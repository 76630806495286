import design from 'design';
import hooks from 'hooks';
import services from 'services';
import './Login.component.scss';

export default (props) => {
    const component = hooks.useComponent({
        name: 'LoginComponent',
        form : {
            defaultValues: {
                username: '',
                password: ''
            }
        }
    });

    const onSubmit = component.useAsync(async ({ username, password }) => {
        const response = await services.authentication.signInWithEmailAndPassword(username, password);
        if(response.success) {
            if(typeof props.onLogin === 'function') {
                props.onLogin({ token: response.payload.token });
            }
            component.form.reset();
        } else {
            component.form.setValue('password', '');
            component.form.setError('onSubmit', {
                type: 'onSubmit',
                message: 'Usuario o contraseña incorrectos'
            }, { life: 4000 });
        }
    });

    return (
        <design.components.layout.Row className={component.classNames(component.name)}>
            <design.components.layout.Col>
                <form onSubmit={component.form.handleSubmit(onSubmit)}>
                    <design.components.layout.Row className="header justify-content-center">
                        <design.components.layout.Col className="logo" col="12">
                            <img 
                                src="https://m.media-amazon.com/images/I/61F5GEsEMQL._AC_SX679_.jpg" 
                                alt="Joyeria Paredes"
                            />
                        </design.components.layout.Col>
                    </design.components.layout.Row>
                    <design.components.layout.Row className="body">
                        <component.form.Controller
                            name="username" 
                            rules={{
                                required: 'El usuario es requerido', 
                                validate: (value) => {
                                    if(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) === false 
                                        && [7, 8, 9].includes(value.length) === false
                                    ) {
                                        return 'Ingresa tú correo o id de usuario';
                                    }
                                    return true;
                                }
                            }}
                            render={({ field, fieldState }) => (
                                <design.components.form.InputText 
                                    {...field}
                                    id={field.name} 
                                    icon="pi pi-user"
                                    label="Correo o usuario"
                                    autoFocus
                                    error={component.form.state.errors.username?.message}
                                    disabled={component.isLoading}
                                    className={component.classNames({ 'p-invalid': fieldState.invalid })}
                                />
                            )} 
                        />
                        <component.form.Controller 
                            name="password"
                            rules={{ required: 'La contraseña es requerida' }}
                            render={({ field, fieldState }) => (
                                <design.components.form.InputPassword
                                    {...field}
                                    id={field.password}
                                    icon="pi pi-lock"
                                    label="Contraseña"
                                    autoFocus
                                    error={component.form.state.errors.password?.message}
                                    feedback={false}
                                    disabled={component.isLoading}
                                    className={component.classNames({ 'p-invalid': fieldState.invalid })}
                                />
                            )}
                        />
                    </design.components.layout.Row>
                    <design.components.layout.Row className="footer">
                        <design.components.layout.Col col="12">
                            <design.components.form.Button 
                                type="submit" 
                                label="Iniciar sesión"
                                loading={component.isLoading}
                            />
                        </design.components.layout.Col>
                    </design.components.layout.Row>
                    {component.form.state.errors.onSubmit &&
                        <design.components.common.Message 
                            severity="warn"
                            text={component.form.state.errors.onSubmit?.message}
                        />
                    }
                </form>
            </design.components.layout.Col>
        </design.components.layout.Row>
    )
};
