import { forwardRef } from 'react';
import ComponentUtils from '../../../utils/component.util';
import './Timer.component.scss';

const Component = {
    name: 'TimerComponent',

    Element: forwardRef((_props, ref) => {
        const className = ComponentUtils.classNames(Component.name, _props.className);

        const days = _props.days !== undefined && <div className={`${Component.name}--days`}>
            <h3>{_props.days}</h3>
            <p>Días</p>
        </div>

        const hours = _props.hours !== undefined && <div className={`${Component.name}--hours`}>
            <h3>{_props.hours}</h3>
            <p>Horas</p>
        </div>

        const minutes = _props.minutes !== undefined && <div className={`${Component.name}--minutes`}>
            <h3>{_props.minutes}</h3>
            <p>Minutos</p>
        </div>

        const seconds = _props.seconds !== undefined && <div className={`${Component.name}--seconds`}>
            <h3>{_props.seconds}</h3>
            <p>Segundos</p>
        </div>
        
        const timer = [days, hours, minutes, seconds]
            .filter((time) => Boolean(time))
            .reduce((timers, time, index, array) => {
                timers.push(time);
                if(index < array.length-1) {
                    timers.push(<span className={`${Component.name}--separator`}>:</span>);
                }
                return timers;
            }, []);
        return (
            <div className={className}>
                {timer}
            </div>
        )
    })
}

export default Component.Element;
