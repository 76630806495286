import authentication from './authentication.service';
import exam from './exam.service';
import exams from './exams.service';
import examTemplate from './examTemplate.service';
import examTemplates from './examTemplates.service';
import user from './user.service';
import userExam from './userExam.service';
import userExams from './userExams.service';
import users from './users.service';

export default {
    authentication,
    exam,
    exams,
    examTemplate,
    examTemplates,
    user,
    userExam,
    userExams,
    users
}
