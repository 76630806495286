import './unauthenticated.base.scss';

const UnauthenticatedBase = (props) => {
    return (
        <div className="UnauthenticatedBase">
            {props.children}
        </div>
    )
}

export default UnauthenticatedBase;
