import CONSTS from 'consts';
import hooks from 'hooks';
import routers from 'routers';

export default () => {
    const authentication = hooks.useAuthentication();
    const component = hooks.useComponent();

    const View = component.useMemo(() => {
        const isAuthenticated = authentication.isAuthenticated();
        if (isAuthenticated === true) {
            if(authentication.isRole(CONSTS.USER.ROLE.ADMIN.ID)) {
                return routers.Admin;
            } else if(authentication.isRole(CONSTS.USER.ROLE.EMPLOYEE.ID)) {
                return routers.Employee;
            }
        } else if (isAuthenticated === false) {
            return routers.Unauthenticated;
        }
        return routers.General;
    }, [authentication.isAuthenticated()])

    return (<View/>)
}
