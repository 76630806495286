import design from 'design';
import hooks from 'hooks';
import './Logout.view.scss';

export default () => {
    const authentication = hooks.useAuthentication();
    const component = hooks.useComponent({
        name: 'LogoutView'
    });

    component.useEffect(() => {
        authentication.signOut();
    }, [])

    return (
        <div className={component.classNames(component.name, 'full-center')}>
            <design.components.common.ProgressSpinner/>
        </div>
    )
};
