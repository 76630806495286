import utils from 'utils';

export default new class extends utils.backend.Request {
    constructor() {
        super({ path: '/api/exam' });
    }

    async list({ id }) {
        const response = await this.get({ id });
        if(response.success) {
            const { exam } = response.payload;
            if(exam) {
                if(exam?.dateStart) {
                    exam.dateStart = new Date(exam.dateStart)
                }
                if(exam?.dateEnd) {
                    exam.dateEnd = new Date(exam.dateEnd);
                }
                if(Array.isArray(exam?.UserExams)) {
                    exam.UserExams.forEach((UserExam) => {
                        if(UserExam?.createdAt) {
                            UserExam.createdAt = new Date(UserExam.createdAt);
                        }
                        if(UserExam?.dateEnd) {
                            UserExam.dateEnd = new Date(UserExam.dateEnd);
                        }
                        if(UserExam?.dateStart) {
                            UserExam.dateStart = new Date(UserExam.dateStart);
                        }
                    })
                }
            }
        }
        return response;
    }

    async create({ exam }) {
        return this.post({ exam });
    }
}();
