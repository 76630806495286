import { Message } from 'primereact/message';
import ComponentUtils from '../../../utils/component.util';
import './Message.component.scss';

const Component = {
    name: 'MessageComponent',

    Element: (_props) => {
        const className = ComponentUtils.classNames(Component.name, _props.className);

        return (
            <div className={className}>
                <Message
                    severity={_props.severity}
                    text={_props.text}
                />
            </div>
        )
    }
}

export default Component.Element;
