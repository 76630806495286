import { InputTextarea } from 'primereact/inputtextarea';
import { forwardRef } from 'react';
import ComponentUtils from '../../../utils/component.util';
import './InputTextArea.component.scss';

const Component = {
    name: 'InputTextAreaComponent',

    Element: forwardRef((_props, ref) => {
        const createBody = () => {
            const classNameBody = `${Component.name}__body`;

            const input =
                (() => {
                    const classNameBodyInput = ['col-12'];
                    if (_props.error) classNameBodyInput.push('p-invalid');

                    return (
                        <span className="p-input-icon-left p-float-label">
                            <i className={_props.icon} />
                            <InputTextarea
                                ref={ref}
                                className={classNameBodyInput.join(' ')}
                                name={_props.name} value={_props.value} onChange={_props.onChange}
                                disabled={_props.disabled}
                                rows={_props.rows}
                                cols={_props.cols}
                                autoResize={_props.autoResize}
                                style={_props.style}
                            />
                            <label>{_props.label}</label>
                        </span>
                    )
                })()

            return (
                <div className={classNameBody}>
                    {input}
                </div>
            )
        }

        const createFooter = () => {
            const classNameFooter = `${Component.name}__footer`;

            const error = _props.error &&
                (() => {
                    const text = _props.error.replace(_props.name, _props.label);
                    return <small className={`${classNameFooter}-error`}>{text}</small>
                })()

            return (
                <div className={classNameFooter}>
                    {error}
                </div>
            )
        }

        const className = ComponentUtils.classNames(Component.name, 'col-12', _props.className);
        const body = createBody();
        const footer = createFooter();

        return (
            <div className={className}>
                {body}
                {footer}
            </div>
        )
    })
}

Component.Element.defaultProps = {

}

export default Component.Element;
