import design from 'design';
import { DateTime } from 'luxon';
import { forwardRef } from 'react';

export default forwardRef((props, ref) => {
    const columnUserCreatedAtBody = (user) => {
        return DateTime.fromJSDate(user.createdAt).toLocal().toFormat('dd/MM/yyyy HH:mm');
    }

    const columnUserRoleBody = (user) => {
        const [role] = user.UserRoles;
        return role.AppUserRole.name;
    }

    return (
        <design.components.data.table.Table
            value={props.users ?? []}
            paginator
            rows={10}
            dataKey="id"
            sortField="name"
            emptyMessage="Sin datos"
            sortOrder={-1}
            filterDisplay="row" 
            selectionMode="single"
            stripedRows
            loading={props.isLoading}
            onRowSelect={props.onRowSelect}
        >
            <design.components.data.table.Column 
                style={{ width: '19%' }}
                field="name"
                header="Nombre"
                sortable
                filter
                showFilterMenu={false}
                filterPlaceholder="Buscar por nombre"
            />
            <design.components.data.table.Column 
                style={{ width: '19%' }}
                field="lastName"
                header="Apellido"
                sortable
                filter
                showFilterMenu={false}
                filterPlaceholder="Buscar por apellido"
            />
            <design.components.data.table.Column 
                style={{ width: '25%' }}
                field="email"
                header="Correo"
                sortable
                filter
                showFilterMenu={false}
                filterPlaceholder="Buscar por correo"
            />
            <design.components.data.table.Column 
                style={{ width: '17%' }}
                field="code"
                header="Codigo"
                sortable
                filter
                showFilterMenu={false}
                filterPlaceholder="Buscar por codigo"
            />
            <design.components.data.table.Column 
                style={{ width: '17%' }}
                header="Rol"
                body={columnUserRoleBody}
                sortable
                // filter
                showFilterMenu={false}
                filterPlaceholder="Buscar por rol"
            />
            <design.components.data.table.Column 
                style={{ width: '20%', textAlign: 'center' }}
                header="Fecha de creación"
                body={columnUserCreatedAtBody}
                sortable
                filter
                showFilterMenu={false}
                filterPlaceholder="Buscar por fecha"
            />
        </design.components.data.table.Table>
    )
});
