import ConfirmDialog from './components/common/confirm/ConfirmDialog.component';
import ConfirmPopup from './components/common/confirm/ConfirmPopup.component';
import Dialog from './components/common/dialog/Dialog.component';
import ListBox from './components/common/listBox/ListBox.component';
import Message from './components/common/message/Message.component';
import ProgressSpinner from './components/common/progressSpinner/ProgressSpinner.component';
import Timer from './components/common/timer/Timer.component';
import Toast from './components/common/toast/Toast.component';
import Info from './components/data/info/DataInfo.component';
import PickList from './components/data/pickList/PickList.component';
import table from './components/data/table/Table.component'
import Button from './components/form/button/Button.component';
import InputCalendar from './components/form/input-calendar/InputCalendar.componenent';
import InputCheckbox from './components/form/input-checkbox/InputCheckbox.component';
import InputMask from './components/form/input-mask/InputMask.componenent';
import InputPassword from './components/form/input-password/InputPassword.component';
import InputSelect from './components/form/input-select/InputSelect.component';
import InputText from './components/form/input-text/InputText.component';
import InputTextArea from './components/form/input-textarea/InputTextArea.component';
import Col from './components/layout/col/Col.component';
import Row from './components/layout/row/Row.component';
import Menu from './components/menu/menu/Menu.component';
import Title from './components/section/title/Title.component';
import confirmDialog from './methods/common/confirm/confirmDialog.method';
import confirmPopup from './methods/common/confirm/confirmPopup.method';

export default {
    components: {
        common: {
            ConfirmDialog,
            ConfirmPopup,
            Dialog,
            ListBox,
            Message,
            ProgressSpinner,
            Timer,
            Toast
        },
        data: {
            Info,
            table,
            PickList
        },
        form: {
            Button,
            InputCalendar,
            InputCheckbox,
            InputMask,
            InputPassword,
            InputSelect,
            InputText,
            InputTextArea
        },
        layout: {
            Col,
            Row
        },
        menu: {
            Menu
        },
        section: {
            Title
        }
    },
    methods: {
        common: {
            confirmDialog,
            confirmPopup
        }
    }
}
