import components from 'components';
import CONSTS from 'consts';
import design from 'design';
import hooks from 'hooks';
import Joi from 'joi';
import { Fragment } from 'react';
import services from 'services';
import utils from 'utils';
import './ExamTemplateForm.component.scss';

export default (props) => {
    const application = hooks.useApplication();
    const component = hooks.useComponent({
        name: 'ExamTemplateFormComponent',
        state: {
            examTemplate: null,
            element: {
                form: {
                    name: {
                        allowUpdate: false
                    },
                    description: {
                        allowUpdate: false
                    },
                    AppExamTemplateStatusId: {
                        allowUpdate: false
                    },
                    question: {
                        allowCreate: false,
                        allowUpdate: false,
                        allowDelete: false
                    },
                    answer: {
                        text: {
                            allowCreate: false,
                            allowUpdate: false,
                            allowDelete: false
                        },
                        checkbox: {
                            allowUpdate: false
                        }
                    },
                    submit: {
                        button: {
                            action: null,
                            label: ''
                        }
                    },
                    errors: {
                        examTemplate: {}
                    }
                }
            }
        }
    });

    const initialExamTemplate = component.useMemo(() => {
        const examTemplate = {
            name: props?.examTemplate?.name ?? '',
            description:  props?.examTemplate?.description ?? '',
            AppExamTemplateStatusId: props?.examTemplate?.AppExamTemplateStatusId ?? CONSTS.EXAM_TEMPLATE.STATUS.ENABLED.ID,
            ExamTemplateQuestions: props?.examTemplate?.ExamTemplateQuestions ?? [
                {
                    text: '',
                    ExamTemplateQuestionAnswers: [
                        {
                            text: '',
                            isValid: false
                        },
                        {
                            text: '',
                            isValid: false
                        }
                    ]
                }
            ]
        }
        if(props?.examTemplate?.id) {
            examTemplate.id = props?.examTemplate?.id;
        }
        return examTemplate;
    }, [props.examTemplate])

    component.useEffect(() => {
        component.setState((state) => {
            const mode = props?.mode ?? 'CREATION';
            switch (mode) {
            case 'CREATION':
                state.element.form.name.allowUpdate = true;
                state.element.form.description.allowUpdate = true;
                state.element.form.AppExamTemplateStatusId.allowUpdate = true;
                state.element.form.question.allowCreate = true;
                state.element.form.question.allowUpdate = true;
                state.element.form.question.allowDelete = true;
                state.element.form.answer.checkbox.allowUpdate = true;
                state.element.form.answer.text.allowCreate = true;
                state.element.form.answer.text.allowUpdate = true;
                state.element.form.answer.text.allowDelete = true;
                state.element.form.submit.button.action = onSubmitModeCreation;
                state.element.form.submit.button.label = 'Crear';
                state.examTemplate = initialExamTemplate;
                break;
            case 'EDITION': 
                state.element.form.name.allowUpdate = true;
                state.element.form.description.allowUpdate = true;
                state.element.form.AppExamTemplateStatusId.allowUpdate = true;
                state.element.form.question.allowUpdate = true;
                state.element.form.answer.checkbox.allowUpdate = true;
                state.element.form.answer.text.allowUpdate = true;
                state.element.form.submit.button.action = onSubmitModeEdition;
                state.element.form.submit.button.label = 'Actualizar';
                state.examTemplate = initialExamTemplate;
                break;
            case 'ANSWER':
                state.element.form.answer.checkbox.allowUpdate = true;
                state.element.form.submit.button.action = onSubmitModeAnswer;
                state.element.form.submit.button.label = 'Finalizar';
                state.examTemplate = initialExamTemplate;
                break;
            default:
                break;
            }
        })
    }, [props.mode, props.examTemplate])

    const validate = (schema, examTemplate) => {
        const validation = schema.validate(examTemplate, { abortEarly: false });
        component.setState((state) => {
            state.element.form.errors.examTemplate = {};
            if(validation.error) {
                validation.error.details.slice().reverse().forEach(({ message, path: paths }) => {
                    const path = paths.reduce((acc, path, index) => {
                        if(path === 'ExamTemplateQuestionAnswers' && index === paths.length -1) {
                            path = 'text';
                        }
                        if(typeof path === 'string' ) {
                            if(index !== 0) {
                                acc += '.';
                            }
                            acc += path;
                        } else if(typeof path === 'number') {
                            acc += `[${path}]`;
                        }
                        return acc;
                    }, '');
                    utils.string.pathToObject(path, message, state.element.form.errors.examTemplate);
                })
            }
        })
        return validation.error === undefined;
    }

    const onSubmitModeCreation = component.useAsync(async ({ examTemplate }) => {
        const schema = Joi.object().keys({
            name: Joi.string().required().messages({ 'string.empty': 'El nombre es requerido' }),
            description: Joi.string().optional().allow(''),
            AppExamTemplateStatusId: Joi.number().required().allow(
                CONSTS.EXAM_TEMPLATE.STATUS.ENABLED.ID,
                CONSTS.EXAM_TEMPLATE.STATUS.DISABLED.ID
            ).messages({ 'number.empty': 'El status es requerido' }),
            ExamTemplateQuestions: Joi.array().items(Joi.object().keys({
                text: Joi.string().required().messages({ 'string.empty': 'Es necesario escribir la pregunta' }),
                ExamTemplateQuestionAnswers: Joi.array().items(Joi.object().keys({
                    text: Joi.string().required().messages({ 'string.empty': 'Es necesario escribir la respuesta' }),
                    isValid: Joi.boolean()
                })).custom((ExamTemplateQuestionAnswers, helper) => {
                    if (ExamTemplateQuestionAnswers.every(({ isValid }) => Boolean(isValid) === false)) {
                        return helper.message('Es necesario especificar minimo una respuesta correcta');
                    } else if (ExamTemplateQuestionAnswers.reduce((accumulator, { isValid }) => Boolean(isValid) ? accumulator + 1 : accumulator, 0) > 1) {
                        return helper.message('Solo puede seleccionar una respuesta valida por pregunta');
                    }
                }).required()
            }))
        });

        if(validate(schema, examTemplate)) {
            const response = await services.examTemplate.create({ examTemplate });
            if(response.success) {
                application.showMessage('success', 'Plantilla creada');
                component.setState((state) => {state.examTemplate = initialExamTemplate})
                application.navigate(CONSTS.MENU.EXAM.TEMPLATES.LIST.PATH);
            } else {
                if(response.error?.internal === false && response.error?.message) {
                    application.showMessage('warn', response.error.message);
                } else {
                    application.showMessage('error', 'Error al crear la plantilla');
                }
            }
        }
    });

    const onSubmitModeEdition = component.useAsync(async ({ examTemplate }) => {
        const schema = Joi.object().keys({
            id: Joi.number().integer().prefs({ convert: true }).required(),
            name: Joi.string().required().messages({ 'string.empty': 'El nombre es requerido' }),
            description: Joi.string().optional().allow(''),
            AppExamTemplateStatusId: Joi.number().required().allow(
                CONSTS.EXAM_TEMPLATE.STATUS.ENABLED.ID,
                CONSTS.EXAM_TEMPLATE.STATUS.DISABLED.ID
            ).messages({ 'number.empty': 'El status es requerido' }),
            ExamTemplateQuestions: Joi.array().items(Joi.object().keys({
                id: Joi.number().integer().prefs({ convert: true }).required(),
                text: Joi.string().required().messages({ 'string.empty': 'Es necesario escribir la pregunta' }),
                ExamTemplateQuestionAnswers: Joi.array().items(Joi.object().keys({
                    id: Joi.number().integer().prefs({ convert: true }).required(),
                    text: Joi.string().required().messages({ 'string.empty': 'Es necesario escribir la respuesta' }),
                    isValid: Joi.boolean()
                })).custom((ExamTemplateQuestionAnswers, helper) => {
                    if(ExamTemplateQuestionAnswers.every(({ isValid }) => Boolean(isValid) === false)) {
                        return helper.message('Es necesario especificar minimo una respuesta correcta');
                    }
                })
            }))
        });

        if(validate(schema, examTemplate)) {
            const { id, name, description, AppExamTemplateStatusId, ExamTemplateQuestions } = examTemplate;
            examTemplate = {
                id, 
                name, 
                description,
                AppExamTemplateStatusId,
                ExamTemplateQuestions: ExamTemplateQuestions.map((ExamTemplateQuestion) => {
                    const { id, text, ExamTemplateQuestionAnswers } = ExamTemplateQuestion;
                    return {
                        id, 
                        text,
                        ExamTemplateQuestionAnswers: ExamTemplateQuestionAnswers.map((ExamTemplateQuestionAnswer) => {
                            const { id, text,  isValid } = ExamTemplateQuestionAnswer;
                            return { id, text,  isValid };
                        })
                    }
                })
            }

            const response = await services.examTemplate.update({ examTemplate });
            if(response.success) {
                application.showMessage('success', 'Plantilla actualizada');
                component.setState((state) => {state.template = initialExamTemplate})
                application.navigate(CONSTS.MENU.EXAM.TEMPLATES.LIST.PATH);
            } else {
                application.showMessage('error', 'Error al actualizar la plantilla');
            }
        }
    });

    const onSubmitModeAnswer = component.useAsync(async ({ examTemplate }) => {
        application.showMessage('success', 'Examen finalizado');
    });

    const onSubmitDisabled = component.useMemo(() => {
        return component.isLoading;
    }, [component.isLoading])

    return (
        <div className={component.classNames(component.name)}>
            {component.state.examTemplate && <>
                <design.components.layout.Row className="general">
                    <design.components.layout.Col col="9" className="pb-0">
                        <design.components.form.InputText 
                            icon="pi pi-pencil"
                            label="Nombre"
                            value={component.state.examTemplate.name}
                            onChange={(event) => {component.setState((state) => {state.examTemplate.name = event.target.value})}}
                            error={component.state.element.form.errors.examTemplate?.name}
                            disabled={component.state.element.form.name.allowUpdate === false || component.isLoading}
                            className={component.classNames({ 'p-invalid': component.state.element.form.errors.examTemplate?.name })}
                        />
                    </design.components.layout.Col>
                    <design.components.layout.Col col="3" className="pb-0">
                        <design.components.form.InputSelect
                            icon="pi pi-user"
                            label="Status"
                            value={component.state.examTemplate.AppExamTemplateStatusId}
                            onChange={(event) => {component.setState((state) => {state.examTemplate.AppExamTemplateStatusId = event.value})}}
                            error={component.state.element.form.errors.examTemplate?.AppExamTemplateStatusId}
                            disabled={component.state.element.form.AppExamTemplateStatusId.allowUpdate === false || component.isLoading}
                            className={component.classNames({ 'p-invalid': component.state.element.form.errors.examTemplate?.AppExamTemplateStatusId })}
                            optionValue="ID"
                            optionLabel="NAME"
                            options={Object.values(CONSTS.EXAM_TEMPLATE.STATUS)}
                        />
                    </design.components.layout.Col>
                    <design.components.layout.Col col="12">
                        <design.components.form.InputTextArea 
                            label="Descripción"
                            value={component.state.examTemplate.description}
                            onChange={(event) => {component.setState((state) => {state.examTemplate.description = event.target.value})}}
                            error={component.state.element.form.errors.examTemplate?.description}
                            disabled={component.state.element.form.description.allowUpdate === false || component.isLoading}
                            className={component.classNames({ 'p-invalid': component.state.element.form.errors.examTemplate?.description })}
                        />
                    </design.components.layout.Col>
                </design.components.layout.Row>
                <design.components.layout.Row>
                    <design.components.layout.Col className="col mr-4">
                        {component.state.examTemplate.ExamTemplateQuestions.map((ExamTemplateQuestion, questionIndex) => (
                            <Fragment key={questionIndex}>
                                <components.ExamTemplateQuestion
                                    component={component}
                                    questionIndex={questionIndex}
                                    questionNumber={questionIndex+1}
                                    ExamTemplateQuestion={ExamTemplateQuestion}
                                />
                            </Fragment>
                        ))}
                    </design.components.layout.Col>
                    <design.components.layout.Col className="navigator col-fixed align-self-end flex align-items-center">
                        <design.components.layout.Row className="m-0">
                            <design.components.layout.Col col="12" className="p-0">
                                <design.components.form.Button 
                                    type="button"
                                    icon="pi pi-sort-up"
                                    rounded
                                    text
                                    onClick={() => {
                                        window.scrollTo({
                                            top: 0,
                                            behavior: 'smooth'
                                        });
                                    }}
                                />
                            </design.components.layout.Col>
                            <design.components.layout.Col col="12" className="p-0">
                                <design.components.form.Button 
                                    type="button"
                                    icon="pi pi-sort-down"
                                    rounded
                                    text
                                    onClick={() => {
                                        window.scrollTo({
                                            top: document.body.scrollHeight,
                                            behavior: 'smooth'
                                        });
                                    }}
                                />
                            </design.components.layout.Col>
                            {component.state.element.form.question.allowCreate &&
                                <design.components.layout.Col col="12" className="p-0 add-question">
                                    <design.components.form.Button 
                                        type="button"
                                        icon="pi pi-plus"
                                        tooltip="Añadir pregunta"
                                        tooltipOptions={{ position: 'top' }}
                                        rounded
                                        text
                                        onClick={() => {
                                            component.setState((state) => {
                                                state.examTemplate.ExamTemplateQuestions.push({
                                                    text: '',
                                                    ExamTemplateQuestionAnswers: [
                                                        {
                                                            text: '',
                                                            isValid: false
                                                        },
                                                        {
                                                            text: '',
                                                            isValid: false
                                                        }
                                                    ]
                                                });
                                            }) 
                                        }}
                                    />
                                </design.components.layout.Col>
                            }
                        </design.components.layout.Row>
                    </design.components.layout.Col>
                    <design.components.layout.Col col="12">
                        <design.components.form.Button 
                            type="submit" 
                            label={component.state.element.form.submit.button.label}
                            onClick={() => { component.state.element.form.submit.button.action({ examTemplate: component.state.examTemplate }) }}
                            disabled={onSubmitDisabled}
                            loading={component.isLoading}
                        />
                    </design.components.layout.Col>
                </design.components.layout.Row>
            </>}
        </div>
    )
};

