import { addLocale } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import { forwardRef } from 'react';
import ComponentUtils from '../../../utils/component.util';
import './InputCalendar.component.scss';

const Component = {
    name: 'InputCalendarComponent',

    Element: forwardRef((_props, ref) => {

        addLocale('es', {
            firstDayOfWeek: 1,
            showMonthAfterYear: true,
            dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
            dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
            dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
            monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
            monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
            today: 'Hoy',
            clear: 'Limpiar'
        });

        const createBody = () => {
            const classNameBody = `${Component.name}__body`;

            const input =
                (() => {
                    const classNameBodyInput = [];
                    if (_props.error) classNameBodyInput.push('p-invalid');

                    return (
                        <span className="p-input-icon-left p-float-label">
                            {_props.icon && <i className={_props.icon} />}
                            <Calendar
                                ref={ref}
                                className={classNameBodyInput}
                                value={_props.value ?? null}
                                onChange={_props.onChange}
                                dateFormat={_props.dateFormat ?? 'mm/dd/yy'}
                                locale={_props.locale ?? 'en'}
                                minDate={_props.minDate}
                                maxDate={_props.maxDate}
                                readOnlyInput={_props.readOnlyInput ?? false}
                                selectionMode={_props.selectionMode ?? 'single'}
                                showButtonBar={_props.showButtonBar ?? false}
                                showTime={_props.showTime ?? false}
                                hourFormat={_props.hourFormat ?? '24'}
                                timeOnly={_props.timeOnly ?? false}
                                view={_props.view ?? 'date'}
                                numberOfMonths={_props.numberOfMonths ?? 1}
                                dateTemplate={_props.dateTemplate}
                                touchUI={_props.touchUI ?? false}
                                inline={_props.inline ?? false}
                                showWeek={_props.showWeek ?? false}
                                disabled={_props.disabled ?? false}
                            />
                            <label>{_props.label}</label>
                        </span>
                    )
                })()

            return (
                <div className={classNameBody}>
                    {input}
                </div>
            )
        }

        const createFooter = () => {
            const classNameFooter = `${Component.name}__footer`;

            const error = _props.error &&
                (() => {
                    const text = _props.error.replace(_props.name, _props.label);
                    return <small className={`${classNameFooter}-error`}>{text}</small>
                })()

            return (
                <div className={classNameFooter}>
                    {error}
                </div>
            )
        }

        const className = ComponentUtils.classNames(Component.name, _props.className);
        const body = createBody();
        const footer = createFooter();

        return (
            <div className={className}>
                {body}
                {footer}
            </div>
        )
    })
}

export default Component.Element;
