import { InputMask } from 'primereact/inputmask';
import { forwardRef } from 'react';
import ComponentUtils from '../../../utils/component.util';
import './InputMask.component.scss';

const Component = {
    name: 'InputMaskComponent',

    Element: forwardRef((_props, ref) => {

        const createBody = () => {
            const classNameBody = `${Component.name}__body`;

            const input =
                (() => {
                    const classNameBodyInput = [];
                    if (_props.error) classNameBodyInput.push('p-invalid');

                    return (
                        <span className="p-input-icon-left p-float-label">
                            {_props.icon && <i className={_props.icon} />}
                            <InputMask
                                ref={ref}
                                className={classNameBodyInput}
                                autoClear={_props.autoClear ?? true}
                                disabled={_props.disabled ?? false}
                                keyfilter={_props.keyfilter ?? null}
                                mask={_props.mask ?? null}
                                pt={_props.pt ?? null}
                                readOnly={_props.readOnly ?? false}
                                required={_props.required ?? false}
                                slotChar={_props.slotChar ?? '_'}
                                tooltip={_props.tooltip ?? null}
                                tooltipOptions={_props.tooltipOptions ?? null}
                                unmask={_props.unmask ?? false}
                                unstyled={_props.unstyled}
                                validateOnly={_props.validateOnly ?? false}
                                onChange={_props.onChange}
                                value={_props.value ?? null}
                                placeholder={_props.placeholder ?? null}
                            />
                            <label>{_props.label}</label>
                        </span>
                    )
                })()

            return (
                <div className={classNameBody}>
                    {input}
                </div>
            )
        }

        const createFooter = () => {
            const classNameFooter = `${Component.name}__footer`;

            const error = _props.error &&
                (() => {
                    const text = _props.error.replace(_props.name, _props.label);
                    return <small className={`${classNameFooter}-error`}>{text}</small>
                })()

            return (
                <div className={classNameFooter}>
                    {error}
                </div>
            )
        }

        const className = ComponentUtils.classNames(Component.name, _props.className);
        const body = createBody();
        const footer = createFooter();

        return (
            <div className={className}>
                {body}
                {footer}
            </div>
        )
    })
}

export default Component.Element;
