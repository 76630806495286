import design from 'design';
import hooks from 'hooks';
import react from 'react';

export const context = react.createContext();
export const Provider = (props) => {
    const component = hooks.useComponent({
        state: {}
    });
    
    const toastRef = react.useRef(null);

    const showMessage = (severity, summary, detail) => {
        toastRef?.current?.show({ severity, summary, detail });
    }

    return (
        <context.Provider value={{
            showMessage,
            state: component.state,
            component
        }}
        >
            <design.components.common.Toast ref={toastRef}/>
            {props.children}
        </context.Provider>
    );
}
