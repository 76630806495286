import { Menubar } from 'primereact/menubar';
import ComponentUtils from '../../../utils/component.util';
import './Menu.component.scss';

const Component = {
    name: 'MenuComponent',

    Element: (props) => {
        const className = ComponentUtils.classNames(Component.name, props.className);

        return (<>
            {props.model && <Menubar {...props} className={className} />}
        </>)
    }
}

export default Component.Element;
