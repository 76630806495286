export default class Component {
    static classNames(...args) {
        if (args) {
            let classes = [];

            for (let i = 0; i < args.length; i++) {
                let className = args[i];

                if (!className) continue;

                const type = typeof className;

                if (type === 'string' || type === 'number') {
                    classes.push(className);
                } else if (type === 'object') {
                    const _classes = Array.isArray(className) 
                        ? className 
                        : Object.entries(className).map(([key, value]) => (!!value ? key : null));

                    classes = _classes.length ? classes.concat(_classes.filter((c) => !!c)) : classes;
                }
            }

            return classes.join(' ');
        }

        return undefined;
    }

    static isFunction(obj) {
        return !!(obj && obj.constructor && obj.call && obj.apply);
    }

    static findDiffKeys(obj1, obj2) {
        if (!obj1 || !obj2) {
            return {};
        }

        return Object.keys(obj1)
            .filter((key) => !obj2.hasOwnProperty(key))
            .reduce((result, current) => {
                result[current] = obj1[current];

                return result;
            }, {});
    }

    static getJSXElement(obj, ...params) {
        return this.isFunction(obj) ? obj(...params) : obj;
    }
}
