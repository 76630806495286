import contexts from 'contexts';
import design from 'design';
import React from 'react';
import ReactDOM from 'react-dom/client';
import * as reactRouterDom from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import routers from './routers';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <reactRouterDom.BrowserRouter>
            <contexts.component.Provider>
                <contexts.application.Provider>
                    <contexts.authentication.Provider>
                        <contexts.template.Provider>
                            <design.components.common.ConfirmDialog/>
                            <design.components.common.ConfirmPopup/>
                            <routers.Main/>
                        </contexts.template.Provider>
                    </contexts.authentication.Provider>
                </contexts.application.Provider>
            </contexts.component.Provider>
        </reactRouterDom.BrowserRouter>
    </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
