import { Dropdown } from 'primereact/dropdown';
import { forwardRef } from 'react';
import ComponentUtils from '../../../utils/component.util';
import './InputSelect.component.scss';

const Component = {
    name: 'InputSelectComponent',
    Element: forwardRef((props, ref) => {
        const createBody = () => {
            const classNameBody = `${Component.name}__body`;

            const input =
                (() => {
                    const classNameBodyInput = ['col-12'];
                    if (props.error) classNameBodyInput.push('p-invalid');

                    return (
                        <span className="p-input-icon-left p-float-label">
                            <i className={props.icon} />
                            <Dropdown
                                className={classNameBodyInput.join(' ')}
                                name={props.name} value={props.value} onChange={props.onChange}
                                disabled={props.disabled}
                                optionLabel={props.optionLabel}
                                options={props.options}
                                emptyMessage={props.emptyMessage}
                                optionValue={props.optionValue}
                            />
                            <label>{props.label}</label>
                        </span>
                    )
                })()

            return (
                <div className={classNameBody}>
                    {input}
                </div>
            )
        }

        const createFooter = () => {
            const classNameFooter = `${Component.name}__footer`;

            const error = props.error &&
                (() => {
                    const text = props.error.replace(props.name, props.label);
                    return <small className={`${classNameFooter}-error`}>{text}</small>
                })()

            return (
                <div className={classNameFooter}>
                    {error}
                </div>
            )
        }

        const className = ComponentUtils.classNames(Component.name, 'col-12', props.className);
        const body = createBody();
        const footer = createFooter();

        return (
            <div className={className}>
                {body}
                {footer}
            </div>
        )
    })
}

Component.Element.defaultProps = {
    emptyMessage: 'Sin resultados'
}

export default Component.Element;
