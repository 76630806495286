export const pathToObject = (path, value, initial = {}) => {
    const pathIsArray = (path) => {
        return path.match(/\[[0-9]+\]/) ? true : false;
    }

    const paths = path.split(/\.|(\[[0-9]+\])/).filter((value) => value);
    paths.reduce((acc, path, currentIndex) => {
        if (pathIsArray(path)) {
            const indexKey = path.replace(/\[|\]/g, '');
            acc[indexKey] = paths.length - 1 === currentIndex
                ? value
                : pathIsArray(paths[currentIndex + 1]) ? [] : {};
            return acc[indexKey];
        } else {
            acc[path] = paths.length - 1 === currentIndex
                ? value
                : pathIsArray(paths[currentIndex + 1]) ? [] : {};
            return acc[path];
        }
    }, initial)
    return initial;
};
