import design from 'design';
import hooks from 'hooks';
import utils from 'utils';

export default (props) => {
    const component = hooks.useComponent({
        name: 'ExamUsersListComponent'
    });

    const columnUserExamDurationBody = (rowData) => {
        const { dateStart, dateEnd } = rowData;
        return utils.app.render(utils.date.timeBetweenDates(dateStart, dateEnd), '-');
    }

    const columnUserExamScoreBody = (rowData) => {
        const { totalQuestions, totalValidAnswers } = rowData;
        const calificacion = (totalValidAnswers * 100) / totalQuestions;
        return `${calificacion}%`;
    }

    const columnUserExamDateEndBody = (rowData) => {
        const { dateEnd } = rowData;
        return utils.app.render(utils.date.format(dateEnd), '-');
    }

    return (<>
        <div className={component.classNames(component.name)}>
            <design.components.data.table.Table
                value={props?.exam?.UserExams ?? []}
                paginator
                rows={10}
                dataKey="id"
                sortField="dateStart"
                emptyMessage="Sin datos"
                sortOrder={-1}
                filterDisplay="row" 
                selectionMode="single"
                stripedRows
                loading={props.isLoading}
                onRowSelect={props.onRowSelect}
            >
                <design.components.data.table.Column 
                    style={{ width: '20%' }}
                    field="User.fullName"
                    header="Usuario"
                    sortable
                    filter
                    showFilterMenu={false}
                    filterPlaceholder="Usuario"
                />
                <design.components.data.table.Column 
                    style={{ width: '20%' }}
                    field="User.email"
                    header="Correo"
                    sortable
                    filter
                    showFilterMenu={false}
                    filterPlaceholder="Correo"
                />
                <design.components.data.table.Column 
                    style={{ width: '15%', textAlign: 'center' }}
                    field="AppUserExamStatus.name"
                    header="Status"
                    sortable
                    filter
                    showFilterMenu={false}
                    filterPlaceholder="Status"
                />
                <design.components.data.table.Column 
                    style={{ width: '10%', textAlign: 'center' }}
                    header="Duración"
                    body={columnUserExamDurationBody}
                    showFilterMenu={false}
                    filterPlaceholder="Duración"
                />
                <design.components.data.table.Column 
                    style={{ width: '10%', textAlign: 'center' }}
                    header="Calificación"
                    body={columnUserExamScoreBody}
                    showFilterMenu={false}
                    filterPlaceholder="Calificación"
                />
                <design.components.data.table.Column 
                    style={{ width: '20%', textAlign: 'center' }}
                    header="Finalización"
                    field="dateEnd"
                    body={columnUserExamDateEndBody}
                    sortable
                    showFilterMenu={false}
                    filterPlaceholder="Finalización"
                />
            </design.components.data.table.Table>
        </div>
    </>)
};
