import CONSTS from 'consts';
import design from 'design';
import { DateTime } from 'luxon';
import { forwardRef } from 'react';

export default forwardRef((props, ref) => {
    const columnUserExamDateStartBody = (userExam) => {
        if(userExam.Exam.dateStart) {
            return DateTime.fromISO(userExam.Exam.dateStart).toLocal().toFormat('dd/MM/yyyy HH:mm');
        }
        return null;
    }

    const columnUserExamDateEndBody = (userExam) => {
        if(userExam.Exam.dateEnd) {
            return DateTime.fromISO(userExam.Exam.dateEnd).toLocal().toFormat('dd/MM/yyyy HH:mm');
        }
        return null;
    }

    const columnScoreBody = (userExam) => {
        switch (userExam?.AppUserExamStatusId) {
        case CONSTS.USER_EXAM.STATUS.CANCELED:
        case CONSTS.USER_EXAM.STATUS.EXPIRED:
        case CONSTS.USER_EXAM.STATUS.FINISHED:
        case CONSTS.USER_EXAM.STATUS.TIME_OUT: {
            const calificacion = (userExam?.totalValidAnswers * 100) / userExam?.totalQuestions;
            return `${calificacion}%`;
        }
        default:
            break;
        }
        return '-';
    }

    return (
        <design.components.data.table.Table
            value={props.userExams ?? []}
            paginator
            rows={10}
            dataKey="id"
            sortField="Exam.dateStart"
            emptyMessage="Sin datos"
            sortOrder={-1}
            filterDisplay="row" 
            selectionMode="single"
            stripedRows
            loading={props.isLoading}
            onRowSelect={props.onRowSelect}
        >
            <design.components.data.table.Column 
                style={{ width: '20%' }}
                header="Nombre"
                sortable
                filter
                showFilterMenu={false}
                filterPlaceholder="Buscar por nombre"
                field="Exam.name"
            />
            <design.components.data.table.Column 
                style={{ width: '20%', textAlign: 'center' }}
                header="Status"
                sortable
                filter
                showFilterMenu={false}
                filterPlaceholder="Buscar por status"
                field="AppUserExamStatus.name"
            />
            <design.components.data.table.Column 
                style={{ width: '20%', textAlign: 'center' }}
                header="Fecha inicio"
                sortable
                filter
                showFilterMenu={false}
                filterPlaceholder="Buscar por fecha"
                field="Exam.dateStart"
                dataType="date"
                body={columnUserExamDateStartBody}
            />
            <design.components.data.table.Column 
                style={{ width: '20%', textAlign: 'center' }}
                header="Fecha limite"
                sortable
                filter
                showFilterMenu={false}
                filterPlaceholder="Buscar por fecha"
                field="Exam.dateStart"
                dataType="date"
                body={columnUserExamDateEndBody}
            />
            <design.components.data.table.Column 
                style={{ width: '20%', textAlign: 'center' }}
                header="Calificación"
                body={columnScoreBody}
            />
        </design.components.data.table.Table>
    )
});
