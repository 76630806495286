import _ from 'lodash';

export const removeIDs = (variable) => {
    if(Array.isArray(variable)) {
        variable = _.cloneDeep(variable).map((subValue) => {
            return removeIDs(subValue);
        });
    } else if(variable !== null && typeof variable === 'object') {
        variable = _.cloneDeep(variable);
        delete variable.id;
        Object.entries(variable).forEach(([key, value]) => {
            variable[key] = removeIDs(value);
        });
    }
    return variable;
};
