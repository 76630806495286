import './authenticated.base.scss';

const AuthenticatedBase = (props) => {
    return (
        <div className="AuthenticatedBase">
            {props.children}
        </div>
    )
}

export default AuthenticatedBase;
