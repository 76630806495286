import axios from 'axios';

const errorHandler = (error) => {
    if (error instanceof axios.AxiosError) {
        if (error.response?.status === 400 && error.response?.data?.message) {
            return { 
                success: false, 
                error: { internal: false, message: error.response?.data?.message } 
            };
        }
    }
    return { success: false, error: { internal: true } };
}

export default {
    get: async (path, options = {}) => {
        try {
            const { data, headers } = await axios.get(path, options);
            return { success: true, body: data, headers };
        }
        catch (error) {
            return errorHandler(error);
        }
    },
    post: async (path, body = undefined, options = {}) => {
        try {
            const { data, headers } = await axios.post(path, body, options);
            return { success: true, body: data, headers };
        }
        catch (error) {
            return errorHandler(error);
        }
    },
    put: async (path, body = undefined, options = {}) => {
        try {
            const { data, headers } = await axios.put(path, body, options);
            return { success: true, body: data, headers };
        }
        catch (error) {
            return errorHandler(error);
        }
    },
    delete: async (path, options = {}) => {
        try {
            const { data, headers } = await axios.delete(path, options);
            return { success: true, body: data, headers };
        }
        catch (error) {
            return errorHandler(error);
        }
    }
};
