import utils from 'utils';

export default new class extends utils.backend.Request {
    constructor() {
        super({ path: '/api/exam/template' });
    }

    async list({ id }) {
        return this.get({ id });
    }

    async create({ examTemplate }) {
        return this.post({ examTemplate });
    }

    async update({ examTemplate }) {
        return this.put({ examTemplate });
    }
}();
