import ComponentUtils from '../../../utils/component.util';
import './Row.component.scss';

const Component = {
    name: 'RowComponent',

    Element: (_props) => {
        const className = ComponentUtils.classNames(
            Component.name,
            'grid',
            _props.className
        );

        return (
            <div className={className}>
                {_props.title &&
                    <div className="title">
                        <span>{_props.title}</span>
                    </div>
                }
                {_props.children}
            </div>
        )
    }
}

export default Component.Element;
