import Admin from './Admin.router';
import Employee from './Employee.router';
import General from './General.router';
import Main from './Main.router';
import Unauthenticated from './Unauthenticated.router';

export default {
    Admin,
    Employee,
    General,
    Main,
    Unauthenticated
}
