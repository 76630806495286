import utils from 'utils';

export default new class extends utils.backend.Request {
    constructor() {
        super({ path: '/api/exams' });
    }

    async list() {
        const response = await this.get();
        if(response.success) {
            const { exams } = response.payload;
            if(exams) {
                exams.forEach((exam) => {
                    exam.createdAt = new Date(exam.createdAt);
                });
            }
        }
        return response;
    }
}();
