import components from 'components';
import CONSTS from 'consts';
import design from 'design';
import hooks from 'hooks';
import services from 'services';

export default () => {
    const application = hooks.useApplication();
    const component = hooks.useComponent({
        name: 'UserExamsListView',
        async: {
            get: async () => {
                const response = await services.userExams.list();
                if(response.success) {
                    return response.payload;
                }
            }
        }
    });

    return (<>
        <design.components.section.Title
            text="Lista de examenes"
        />
        <components.UserExamsList
            isLoading={component.async.get.status === 'loading'}
            userExams={component.async.get.data?.userExams}
            onRowSelect={({ data: userExam }) => {
                const { id } = userExam;
                application.navigate(CONSTS.MENU.USER.EXAM.DETAILS.PATH, { id });
            }}
        />
    </>)
};
