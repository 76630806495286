import components from 'components';
import design from 'design';

export default () => {
    return (<>
        <design.components.section.Title
            goBack
            text="Asignar examen"
        />
        <components.ExamForm
            mode="ASSIGN"
        />
    </>)
};
