import * as application from './application.context';
import * as authentication from './authentication.context';
import * as component from './component.context';
import * as template from './template.context';

export default {
    application,
    authentication,
    component,
    template
}
