import CONSTS from 'consts';
import * as app from './app.util';
import requestUtil from './request.util';

export class Request {
    constructor({ path = '' }) {
        this.path = CONSTS.BACKEND.BASE_URL + path;
    }

    #setAuthorization(headers = {}) {
        headers.authorization = app.cache.get('token');
        return headers;
    }

    #mapParams(...params) {
        const request = {
            path: this.path,
            data: {},
            options: {}
        };

        let indexParameter = 0;
        if(typeof params[indexParameter] === 'string') {
            request.path += params[indexParameter];
            indexParameter++;
        }
        if(typeof params[indexParameter] === 'object') {
            request.data = params[indexParameter];
            indexParameter++;
        }
        if(typeof params[indexParameter] === 'object') {
            request.options = params[indexParameter];
        }

        if(!request.options?.headers?.authorization) {
            request.options.headers = this.#setAuthorization(request.options?.headers);
        }

        return request;
    }

    async get(...params) {
        const request = this.#mapParams(...params);
        const { success, body, error } = await requestUtil.get(request.path, { headers: request.options?.headers , params: request.data });
        return success ? { success, payload: body } : { success, error };
    }

    async post(...params) {
        const request = this.#mapParams(...params);
        const { success, body, error } = await requestUtil.post(request.path, request.data, request.options);
        return success ? { success, payload: body } : { success, error };
    }

    async put(...params) {
        const request = this.#mapParams(...params);
        const { success, body, error } = await requestUtil.put(request.path, request.data, request.options);
        return success ? { success, payload: body } : { success, error };
    }
}
