import design from 'design';
import logo from 'resources/images/logo-circle-black-on-transparent-background.png';
import './Menu.component.scss';

export default (props) => {
    return (
        <design.components.menu.Menu
            start={<>
                <img className="logo" src={logo} alt="Joyería Paredes"/>
                <div className="text">
                    <p className="name merriweather-regular-italic">PAREDES</p>
                    <p className="slogan merriweather-light-italic">El detalle que te hará brillar</p>
                </div>
            </>}
            model={props.model}
        />
    )
};
