import contexts from 'contexts';
import react from 'react';
import * as reactRouterDom from 'react-router-dom';

export default () => {
    const { showMessage } = react.useContext(contexts.application.context);
    const navigate = reactRouterDom.useNavigate();
    const location = reactRouterDom.useLocation();
    const params = reactRouterDom.useParams();

    const customNavigate = (path = '', params = {}, state = {}) => {
        if(params !== null && typeof params === 'object') {
            Object.entries(params).forEach(([key, value]) => {
                path = path.replaceAll(`:${key}`, value);
            });
        }
        return navigate(path, { state });
    }

    return {
        navigate: customNavigate,
        location: { ...location, ...params },
        showMessage
    }
};
