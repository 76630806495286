import design from 'design';
import { DateTime } from 'luxon';

export default (props) => {
    const columnExamTemplateCreatedAtBody = (examTemplate) => {
        return DateTime.fromJSDate(examTemplate.createdAt).toLocal().toFormat('dd/MM/yyyy HH:mm');
    }

    return (
        <design.components.data.table.Table
            value={props?.examTemplates ?? []}
            paginator
            rows={10}
            dataKey="id"
            sortField="createdAt"
            emptyMessage="Sin datos"
            sortOrder={-1}
            filterDisplay="row" 
            selectionMode="single"
            stripedRows
            loading={props.isLoading}
            onRowSelect={props.onRowSelect}
        >
            <design.components.data.table.Column 
                style={{ width: '25%' }}
                field="name"
                header="Nombre"
                sortable
                filter
                showFilterMenu={false}
                filterPlaceholder="Nombre"
            />
            <design.components.data.table.Column 
                style={{ width: '25%' }}
                field="description"
                dataType="date"
                header="Descripción"
                sortable
                filter
                showFilterMenu={false}
                filterPlaceholder="Descripción"
            />
            <design.components.data.table.Column 
                style={{ width: '10%', textAlign: 'center' }}
                field="ExamTemplateQuestions.length"
                header="Preguntas"
                sortable
                filter
                showFilterMenu={false}
                filterPlaceholder="Preguntas"
            />
            <design.components.data.table.Column 
                style={{ width: '20%', textAlign: 'center' }}
                field="AppExamTemplateStatus.name"
                dataType="date"
                header="Status"
                sortable
                filter
                showFilterMenu={false}
                filterPlaceholder="Status"
            />
            <design.components.data.table.Column 
                style={{ width: '20%', textAlign: 'center' }}
                field="createdAt"
                header="Fecha de creación"
                body={columnExamTemplateCreatedAtBody}
                sortable
                filter
                showFilterMenu={false}
                filterPlaceholder="Fecha"
            />
        </design.components.data.table.Table>
    )
};
