import components from 'components';
import CONSTS from 'consts';
import design from 'design';
import hooks from 'hooks';
import services from 'services';

export default () => {
    const authentication = hooks.useAuthentication();
    const application = hooks.useApplication();
    const component = hooks.useComponent({
        name: 'UserExamDetailsView',
        async: {
            key: [application.location.id],
            get: async () => {
                const response = await services.userExam.details({ id: application.location.id });
                if(response.success) {
                    const { userExam } = response.payload;
                    if(Array.isArray(userExam?.Exam?.ExamTemplate?.ExamTemplateQuestions) && Array.isArray(userExam?.UserExamAnswers)) {
                        userExam.Exam.ExamTemplate.ExamTemplateQuestions.forEach((ExamTemplateQuestion) => {
                            const { ExamTemplateQuestionAnswers } = ExamTemplateQuestion;
                            ExamTemplateQuestionAnswers.forEach((ExamTemplateQuestionAnswer) => {
                                const { id } = ExamTemplateQuestionAnswer;
                                const isSelected = userExam.UserExamAnswers.some(({ ExamTemplateQuestionAnswerId }) => ExamTemplateQuestionAnswerId === id);
                                ExamTemplateQuestionAnswer.isSelected = isSelected;
                            });
                        })
                    }
                    return response.payload;
                }
                application.navigate(CONSTS.MENU.USER.EXAMS.LIST.PATH);
                return null;
            }
        }
    });

    const titleButtons = component.useMemo(() => {
        const buttons = [];
        const userExam = component.async.get.data?.userExam ?? {};
        if(authentication.isRole(CONSTS.USER.ROLE.EMPLOYEE.ID)) {
            switch (userExam.AppUserExamStatusId) {
            case CONSTS.USER_EXAM.STATUS.ASSIGNED: {
                buttons.push(
                    <design.components.form.Button 
                        type="submit" 
                        icon="pi pi-pencil"
                        label="Iniciar"
                        onClick={(event) => {
                            design.methods.common.confirmPopup({
                                target: event.currentTarget,
                                message: 'Estas seguro que deseas continuar?',
                                icon: 'pi pi-exclamation-triangle',
                                acceptLabel: 'Sí',
                                accept: async () => {
                                    const response = await services.userExam.start({ 
                                        id: application.location.id,
                                        sessionId: authentication.socket.id
                                    });
                                    if(response.success) {
                                        if(response.payload.started) {
                                            application.navigate(CONSTS.MENU.USER.EXAM.REPLY.PATH, { id: application.location.id });
                                        }
                                    }
                                }
                            });
                        }}
                    />
                );
                break;
            }
            case CONSTS.USER_EXAM.STATUS.INITIATED: {
                buttons.push(
                    <design.components.form.Button 
                        type="submit" 
                        icon="pi pi-play"
                        label="Retomar"
                        onClick={(event) => {
                            design.methods.common.confirmPopup({
                                target: event.currentTarget,
                                message: 'Estas seguro que deseas continuar?',
                                icon: 'pi pi-exclamation-triangle',
                                acceptLabel: 'Sí',
                                accept: async () => {
                                    const response = await services.userExam.start({ 
                                        id: application.location.id,
                                        sessionId: authentication.socket.id
                                    });
                                    if(response.success) {
                                        if(response.payload.started) {
                                            application.navigate(CONSTS.MENU.USER.EXAM.REPLY.PATH, { id: application.location.id });
                                        }
                                    }
                                }
                            });
                        }}
                    />
                );
                break;
            }
            default: 
                break;
            }
        }
        return buttons;
    }, [authentication.user, component.async.get.data?.userExam])

    return (<>
        <design.components.section.Title
            goBack
            isLoading={component.async.get.status === 'loading'}
            text={`Detalles del examen: ${component.async.get.data?.userExam.Exam.name ?? ''}`}
            buttons={titleButtons}
        />
        <components.UserExamDetails
            isLoading={component.async.get.status === 'loading'}
            userExam={component.async.get.data?.userExam}
        />
    </>)
};
