import components from 'components';
import CONSTS from 'consts';
import design from 'design';
import hooks from 'hooks';
import services from 'services';

export default () => {
    const component = hooks.useComponent({
        name: 'UsersListView',
        state: {
            element: {
                DialogUser: {
                    visible: false,
                    title: CONSTS.MENU.USER.CREATE.LABEL,
                    mode: 'CREATION',
                    user: null
                }
            }
        },
        async: {
            get: async () => {
                const response = await services.users.list();
                if(response.success) {
                    return response.payload;
                }
            }
        }
    });

    return (<>
        <design.components.section.Title
            text="Lista de usuarios"
            buttons={[
                <design.components.form.Button 
                    type="submit" 
                    icon="pi pi-plus"
                    label={CONSTS.MENU.USER.CREATE.LABEL}
                    onClick={() => {
                        component.setState((state) => {
                            state.element.DialogUser.visible = true;
                            state.element.DialogUser.title = CONSTS.MENU.USER.CREATE.LABEL;
                            state.element.DialogUser.mode = 'CREATION';
                            state.element.DialogUser.user = null;
                        })
                    }}
                />
            ]}
        />
        <components.UsersList
            isLoading={component.async.get.status === 'loading'}
            users={component.async.get.data?.users}
            onRowSelect={({ data: user }) => {
                component.setState((state) => {
                    state.element.DialogUser.visible = true;
                    state.element.DialogUser.title = 'Actualizar usuario';
                    state.element.DialogUser.mode = 'EDITION';
                    state.element.DialogUser.user = user;
                })
            }}
        />
        <design.components.common.Dialog
            header={component.state.element.DialogUser.title}
            visible={component.state.element.DialogUser.visible}
            onHide={() => component.setState((state) => {state.element.DialogUser.visible = false})}
            style={{ width: '60vw' }}
        >
            <components.UserForm 
                mode={component.state.element.DialogUser.mode}
                user={component.state.element.DialogUser.user}
                onSuccess={() => {
                    component.setState((state) => {state.element.DialogUser.visible = false});
                    component.async.refetch();
                }}
            />
        </design.components.common.Dialog>
    </>)
};
