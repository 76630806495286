import { cloneElement } from 'react';
import { useNavigate } from 'react-router-dom';
import ComponentUtils from '../../../utils/component.util';
import ColComponent from '../../layout/col/Col.component';
import RowComponent from '../../layout/row/Row.component';
import './Title.component.scss';

const Component = {
    name: 'TitleComponent',

    Element: (_props) => {
        const navigate = useNavigate();

        const createTitle = () => {
            const className = `${Component.name}__title`;

            const goBack = _props.goBack && <span className={`${className}__goBack`} onClick={() => navigate(-1)}>
                <i className="pi pi-angle-double-left" style={{ 'fontSize': '1.5em' }} />
            </span>;

            const loading = _props.isLoading === true && <span className={`${className}__loading`}>
                <i className="pi pi-spin pi-spinner" style={{ 'fontSize': '1.5em' }} />
            </span>;
            
            const text = _props.text && <h1 className={`${className}__text`}>{_props.text}</h1>;

            return (
                <ColComponent className={`${className} flex`} col="12" sm="12" md="6">
                    {goBack}
                    {loading || text}
                </ColComponent>
            );
        }

        const createButtons = () => {
            const className = `${Component.name}__buttons`;

            let buttons = undefined;
            if(Array.isArray(_props.buttons)) {
                buttons = _props.buttons.map((Button, key) => {
                    return cloneElement(Button, { key });
                });
            } else if(typeof _props.buttons === 'object') {
                buttons = _props.buttons;
            }

            return (
                <ColComponent className={`${className} flex justify-content-end`} col="12" sm="12" md="6">
                    {buttons}
                </ColComponent>
            )
        }

        const className = ComponentUtils.classNames(Component.name, _props.className);
        const title = createTitle();
        const buttons = createButtons();

        return (
            <RowComponent className={className}>
                {title}
                {buttons}
            </RowComponent>
        )
    }
}

export default Component.Element;
