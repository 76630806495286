import contexts from 'contexts';
import react from 'react';

export default () => {
    const { state, setState, getUserDataAccount } = 
        react.useContext(contexts.authentication.context);

    const isAuthenticated = () => {
        return state?.user === undefined ? undefined : state.user !== null;
    }

    const setToken = (token) => {
        setState(state => {state.token = token});
    }

    const signOut = async () => {
        setState(state => {state.token = null});
    }

    const isRole = (roleId) => {
        if(Array.isArray(state?.user?.roles) && state.user.roles.find(({ AppUserRoleId }) => AppUserRoleId === roleId) !== undefined) {
            return true;
        }
        return false;
    }

    return {
        state,
        user: state.user,
        socket: state.socket,
        isAuthenticated,
        isRole,
        setToken,
        signOut,
        reload: getUserDataAccount
    };
};
