export default {
    STATUS : {
        ASSIGNED: 1,
        INITIATED: 2,
        TIME_OUT: 3,
        CANCELED: 4,
        EXPIRED: 5,
        FINISHED: 6
    }
};
