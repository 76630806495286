import BACKEND from './BACKEND.CONSTS';
import EXAM from './EXAM.CONSTS';
import EXAM_TEMPLATE from './EXAM_TEMPLATE.CONSTS';
import MENU from './MENU.CONSTS';
import TEMPLATE from './TEMPLATE.CONSTS';
import USER from './USER.CONSTS';
import USER_EXAM from './USER_EXAM.CONSTS';

export default {
    BACKEND,
    EXAM,
    EXAM_TEMPLATE,
    TEMPLATE,
    MENU,
    USER,
    USER_EXAM
}
