import base from 'base';
import components from 'components';
import CONSTS from 'consts';
import hooks from 'hooks';
import * as reactRouterDom from 'react-router-dom';
import Views from 'views';

export default () => {
    const authentication = hooks.useAuthentication();
    const application = hooks.useApplication();
    const component = hooks.useComponent();
    const model = component.useMemo(() => {
        // const { pathname } = location;

        return [
            {
                label: CONSTS.MENU.USERS.LIST.LABEL,
                command: () => {
                    application.navigate(CONSTS.MENU.USERS.LIST.PATH);
                }
            },
            {
                label: CONSTS.MENU.EXAMS.LIST.LABEL,
                // className: component.classNames({ 'active': pathname === CONSTS.MENU.EXAMS.ROOT.PATH }),
                items: [
                    {
                        label: CONSTS.MENU.EXAMS.LIST.LABEL,
                        command: () => {
                            application.navigate(CONSTS.MENU.EXAMS.LIST.PATH);
                        }
                    },
                    {
                        label: CONSTS.MENU.EXAM.TEMPLATES.LIST.LABEL,
                        command: () => {
                            application.navigate(CONSTS.MENU.EXAM.TEMPLATES.LIST.PATH);
                        }
                    }
                ]
            },
            {
                label: <div className="menu-session">
                    <span className="logout">{CONSTS.MENU.LOGOUT.LABEL}</span>
                    <span className="username">{authentication.user.email}</span>
                </div>,
                command: () => {
                    application.navigate(CONSTS.MENU.LOGOUT.PATH);
                }
            }
        ]
    }, [application.location])

    return (
        <base.Authenticated>
            <components.Menu 
                model={model}
            />
            <div className="container">
                <reactRouterDom.Routes>
                    <reactRouterDom.Route path="/" element={<span>/</span>}/>
                    <reactRouterDom.Route path={CONSTS.MENU.USER.CREATE.PATH} element={<Views.UserCreate/>}/>
                    <reactRouterDom.Route path={CONSTS.MENU.USER.DETAILS.PATH} element={<Views.UserDetails/>}/>
                    <reactRouterDom.Route path={CONSTS.MENU.USERS.LIST.PATH} element={<Views.UsersList/>}/>
                    <reactRouterDom.Route path={CONSTS.MENU.EXAMS.LIST.PATH} element={<Views.ExamsList/>}/>
                    <reactRouterDom.Route path={CONSTS.MENU.EXAM.ASSIGN.PATH} element={<Views.ExamAssign/>}/>
                    <reactRouterDom.Route path={CONSTS.MENU.EXAM.DETAILS.PATH} element={<Views.ExamDetails/>}/>
                    <reactRouterDom.Route path={CONSTS.MENU.EXAM.TEMPLATE.CREATE.PATH} element={<Views.ExamTemplateCreate/>}/>
                    <reactRouterDom.Route path={CONSTS.MENU.EXAM.TEMPLATE.DETAILS.PATH} element={<Views.ExamTemplateDetails/>}/>
                    <reactRouterDom.Route path={CONSTS.MENU.EXAM.TEMPLATES.LIST.PATH} element={<Views.ExamTemplatesList/>}/>
                    <reactRouterDom.Route path={CONSTS.MENU.USER.EXAM.DETAILS.PATH} element={<Views.UserExamDetails/>}/>
                    <reactRouterDom.Route path={CONSTS.MENU.LOGOUT.PATH} element={<Views.Logout/>}/>
                    <reactRouterDom.Route path="*" element={<reactRouterDom.Navigate to="/" replace />}/>
                </reactRouterDom.Routes>
            </div>
        </base.Authenticated>
    )
};
