const CACHE_KEY = 'app';
export const cache = {
    get: (field) => {
        const app = localStorage.getItem(CACHE_KEY);
        if (!app) {
            return undefined;
        } else {
            const data = JSON.parse(app);
            return data[field];
        }
    },
    set: (field, data) => {
        const app = localStorage.getItem(CACHE_KEY);
        if (!app) {
            const cached = {};
            cached[field] = data;
            localStorage.setItem(CACHE_KEY, JSON.stringify(cached));
        } else {
            const cached = JSON.parse(app);
            cached[field] = data;
            localStorage.setItem(CACHE_KEY, JSON.stringify(cached));
        }
    },
    delete: (field) => {
        const app = localStorage.getItem(CACHE_KEY);
        if (app) {
            const cached = JSON.parse(app);
            delete cached[field];
            localStorage.setItem(CACHE_KEY, JSON.stringify(cached));
        }
    }
}

export const env = (PATH, { value, prefix } = {}) => {
    const PREFIX_ENVIROMENT_NAME = 'REACT_APP';
    const environment = prefix ?? process.env[`${PREFIX_ENVIROMENT_NAME}_APP_ENV`] ?? 'development';
    const ENVIRONMENT = environment.toUpperCase();
    return process.env[`${PREFIX_ENVIROMENT_NAME}_${ENVIRONMENT}_${PATH}`] ?? value;
}

export const render = (value, elseCase = '') => {
    if([null, undefined].includes(value)) {
        return elseCase;
    }
    return value;
};
