import utils from 'utils';

export default new class extends utils.backend.Request {
    constructor() {
        super({ path: '/api/authentication' });
    }

    async getInfo() {
        return this.get('/info');
    }

    async signInWithEmailAndPassword(user, password) {
        return this.post('/token', { user, password });
    }
}();
