import base from 'base';
import * as reactRouterDom from 'react-router-dom';
import views from 'views';

export default () => {
    return (
        <base.General>
            <reactRouterDom.Routes>
                <reactRouterDom.Route path="*" element={<views.Loading/>}/>
            </reactRouterDom.Routes>
        </base.General>
    )
};
