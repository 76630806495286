import { DateTime, Duration } from 'luxon';

export const format = (date, options = {}) => {
    const locale = options?.locale ?? 'es-US';
    const format = options?.format ?? 'dd LLLL yyyy';
    if(date) {
        return DateTime.fromJSDate(date).setLocale(locale).toFormat(format);
    }
    return undefined;
};

export const minutesToHours = (minutes) => {
    if([undefined, null].includes(minutes) === false) {
        return Duration.fromMillis(minutes * 60000).toFormat('hh:mm:ss');
    }
    return undefined;
};

export const timeBetweenDates = (dateStart, dateEnd) => {
    if(dateStart && dateEnd) {
        const start = DateTime.fromJSDate(dateStart);
        const end = DateTime.fromJSDate(dateEnd);
        const diff = end.diff(start, ['days', 'hours', 'minutes', 'seconds', 'milliseconds']).toObject();
        return Duration.fromObject(diff).toFormat('hh:mm:ss');
    }
    return undefined;
};
