export default {
    STATUS: {
        ENABLED: {
            ID: 1,
            NAME: 'Habilitado'
        },
        DISABLED: {
            ID: 2,
            NAME: 'Deshabilitado'
        }
    },
    MODE: {
        ASCENDING: {
            ID: 1,
            NAME: 'Ascendente'
        },
        DESCENDING: {
            ID: 2,
            NAME: 'Descendente'
        },
        RANDOM: {
            ID: 3,
            NAME: 'Aleatorio'
        }
    }
};
