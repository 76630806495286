import base from 'base';
import CONSTS from 'consts';
import * as reactRouterDom from 'react-router-dom';
import views from 'views';

export default () => {
    return (
        <base.Unauthenticated>
            <reactRouterDom.Routes>
                <reactRouterDom.Route path="/" element={<reactRouterDom.Navigate to={CONSTS.MENU.LOGIN.PATH} replace />}/>
                <reactRouterDom.Route path={CONSTS.MENU.LOGIN.PATH} element={<views.Login/>}/>
                <reactRouterDom.Route path="*" element={<reactRouterDom.Navigate to="/" replace />}/>
            </reactRouterDom.Routes>
        </base.Unauthenticated>
    )
};
