import ExamDetails from './ExamDetails/ExamDetails.component';
import ExamForm from './ExamForm/ExamForm.component';
import ExamsList from './ExamsList/ExamsList.component';
import ExamTemplateForm from './ExamTemplateForm/ExamTemplateForm.component';
import ExamTemplateQuestion from './ExamTemplateQuestion/ExamTemplateQuestion.component';
import ExamTemplateQuestionAnswer from './ExamTemplateQuestionAnswer/ExamTemplateQuestionAnswer.component';
import ExamTemplatesList from './ExamTemplatesList/ExamTemplatesList.component';
import ExamUsersList from './ExamUsersList/ExamUsersList.component';
import Login from './Login/Login.component';
import Menu from './Menu/Menu.component';
import UserExamDetails from './UserExamDetails/UserExamDetails.component';
import UserExamsList from './UserExamsList/UserExamsList.component';
import UserForm from './UserForm/UserForm.component';
import UsersList from './UsersList/UsersList.component';

export default {
    ExamDetails,
    ExamForm,
    ExamsList,
    ExamTemplateForm,
    ExamTemplateQuestion,
    ExamTemplateQuestionAnswer,
    ExamTemplatesList,
    ExamUsersList,
    Login,
    Menu,
    UserExamDetails,
    UserExamsList,
    UserForm,
    UsersList
}
