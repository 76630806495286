import components from 'components';
import CONSTS from 'consts';
import design from 'design';
import hooks from 'hooks';
import services from 'services';
import utils from 'utils';

export default () => {
    const application = hooks.useApplication();
    const component = hooks.useComponent({
        name: 'ExamTemplateDetailsView',
        async: {
            key: [application.location.id],
            get: async () => {
                const { id } = application.location;
                const response = await services.examTemplate.list({ id });
                if(response.success) {
                    const { id, name, description, AppExamTemplateStatusId, ExamTemplateQuestions } = response.payload.examTemplate;
                    const examTemplate = {
                        id, 
                        name, 
                        description,
                        AppExamTemplateStatusId,
                        ExamTemplateQuestions: ExamTemplateQuestions.map((ExamTemplateQuestion) => {
                            const { id, text, ExamTemplateQuestionAnswers } = ExamTemplateQuestion;
                            return {
                                id, 
                                text,
                                ExamTemplateQuestionAnswers: ExamTemplateQuestionAnswers.map((ExamTemplateQuestionAnswer) => {
                                    const { id, text, isValid } = ExamTemplateQuestionAnswer;
                                    return { id, text, isValid };
                                })
                            }
                        })
                    }
                    return { examTemplate };
                }
                application.navigate(CONSTS.MENU.EXAM.TEMPLATES.LIST.PATH);
                return null;
            }
        }
    });

    return (<>
        <design.components.section.Title
            goBack
            text={`Detalles de la plantilla: ${component.async.get.data?.examTemplate?.name}`}
            buttons={[
                <design.components.form.Button 
                    type="submit" 
                    icon="pi pi-copy"
                    label={CONSTS.MENU.EXAM.TEMPLATE.CLONE.LABEL}
                    disabled={component.async.get.status === 'loading' || Boolean(component.async.get.data.examTemplate) === false}
                    onClick={() => {
                        const examTemplate = utils.common.removeIDs(component.async.get.data.examTemplate);
                        application.navigate(CONSTS.MENU.EXAM.TEMPLATE.CREATE.PATH, null, {
                            examTemplate
                        });
                    }}
                />
            ]}
        />
        <components.ExamTemplateForm
            mode="EDITION"
            isLoading={component.async.get.status === 'loading'}
            examTemplate={component.async.get.data?.examTemplate}
        />
    </>)
};
