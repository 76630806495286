import design from 'design';
import hooks from 'hooks';
import './ExamTemplateQuestionAnswer.component.scss';

export default (props) => {
    const component = hooks.useComponent({
        name: 'ExamTemplateQuestionAnswerComponent',
        state: {
            row: 1
        }
    });

    const element = {
        InputTextArea: {
            ref: component.useRef(null)
        }
    }

    component.useEffect(() => {
        if(element.InputTextArea.ref?.current?.scrollHeight > 0) {
            const inputTextArea = element.InputTextArea.ref.current;
            const scrollHeight = inputTextArea.scrollHeight;
            inputTextArea.style.height = `${scrollHeight}px`;
        }
    }, [
        element.InputTextArea.ref?.current?.scrollHeight
    ])

    return (
        <design.components.layout.Col col="12" className={component.classNames(component.name, 'py-0')}>
            <design.components.layout.Row>
                <design.components.layout.Col className="col-fixed options isValid">
                    <design.components.form.InputCheckbox
                        checked={props.component.state.examTemplate.ExamTemplateQuestions[props.questionIndex].ExamTemplateQuestionAnswers[props.answerIndex].isValid}
                        onChange={(event) => {
                            props.component.setState((state) => {
                                if(event.checked) {
                                    state.examTemplate.ExamTemplateQuestions[props.questionIndex].ExamTemplateQuestionAnswers.forEach((ExamTemplateQuestionAnswer) => {
                                        ExamTemplateQuestionAnswer.isValid = false;
                                    })
                                }
                                state.examTemplate.ExamTemplateQuestions[props.questionIndex].ExamTemplateQuestionAnswers[props.answerIndex].isValid = event.checked
                            })
                        }}
                        disabled={props.component.state?.element?.form?.answer?.checkbox?.allowUpdate === false || props.component.isLoading}
                    />
                </design.components.layout.Col>
                {props.component.state.examTemplate.ExamTemplateQuestions[props.questionIndex].ExamTemplateQuestionAnswers[props.answerIndex].isSelected !== undefined &&
                    <design.components.layout.Col className={`col-fixed options isSelected ${props.component.state.examTemplate.ExamTemplateQuestions[props.questionIndex].ExamTemplateQuestionAnswers[props.answerIndex].isValid ? 'valid' : 'invalid'}`}>
                        <design.components.form.InputCheckbox
                            checked={props.component.state.examTemplate.ExamTemplateQuestions[props.questionIndex].ExamTemplateQuestionAnswers[props.answerIndex].isSelected}
                            onChange={(event) => {
                                props.component.setState((state) => {
                                    if(event.checked) {
                                        state.examTemplate.ExamTemplateQuestions[props.questionIndex].ExamTemplateQuestionAnswers.forEach((ExamTemplateQuestionAnswer) => {
                                            ExamTemplateQuestionAnswer.isSelected = false;
                                        })
                                    }
                                    state.examTemplate.ExamTemplateQuestions[props.questionIndex].ExamTemplateQuestionAnswers[props.answerIndex].isSelected = event.checked
                                })
                            }}
                            disabled={props.component.state?.element?.form?.answer?.checkbox?.allowUpdate === false || props.component.isLoading}
                        />
                    </design.components.layout.Col>
                }
                <design.components.layout.Col className="col">
                    <design.components.form.InputTextArea
                        ref={element.InputTextArea.ref}
                        icon="pi pi-align-justify"
                        label={`Opción ${props.answerNumber}`}
                        value={props.component.state.examTemplate.ExamTemplateQuestions[props.questionIndex].ExamTemplateQuestionAnswers[props.answerIndex].text}
                        onChange={(event) => {props.component.setState((state) => {state.examTemplate.ExamTemplateQuestions[props.questionIndex].ExamTemplateQuestionAnswers[props.answerIndex].text = event.target.value})}}
                        error={props.component.state?.element?.form?.errors?.examTemplate?.ExamTemplateQuestions?.[props.questionIndex]?.ExamTemplateQuestionAnswers?.[props.answerIndex]?.text}
                        disabled={props.component.state?.element?.form?.answer?.text?.allowUpdate === false || props.component.isLoading}
                        className={props.component.classNames({ 'p-invalid': props.component.state?.element?.form?.errors?.examTemplate?.ExamTemplateQuestions?.[props.questionIndex]?.ExamTemplateQuestionAnswers?.[props.answerIndex]?.text })}
                        rows={1}
                    />
                </design.components.layout.Col>
                {props.component.state?.element?.form?.answer?.text?.allowDelete && Object.keys(props.component.state.examTemplate.ExamTemplateQuestions[props.questionIndex].ExamTemplateQuestionAnswers).length >= 3 &&
                    <design.components.layout.Col className="col-fixed actions">
                        <design.components.form.Button
                            type="button"
                            icon="pi pi-times"
                            severity="danger"
                            tooltip="Eliminar respuesta"
                            tooltipOptions={{ position: 'top' }}
                            rounded
                            text
                            onClick={(event) => {
                                const { text } = props.component.state.examTemplate.ExamTemplateQuestions[props.questionIndex].ExamTemplateQuestionAnswers[props.answerIndex];
                                const accept = () => {
                                    props.component.setState((state) => {
                                        state.examTemplate.ExamTemplateQuestions[props.questionIndex].ExamTemplateQuestionAnswers.splice(props.answerIndex, 1);
                                    })
                                }
                                if(text) {
                                    design.methods.common.confirmPopup({
                                        target: event.currentTarget,
                                        message: 'Estas seguro que deseas continuar?',
                                        icon: 'pi pi-exclamation-triangle',
                                        acceptLabel: 'Sí',
                                        accept
                                    });
                                } else {
                                    accept();
                                }
                            }}
                        />
                    </design.components.layout.Col>
                }
            </design.components.layout.Row>
        </design.components.layout.Col>
    )
};
