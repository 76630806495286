import CONSTS from 'consts';
import design from 'design';
import { DateTime } from 'luxon';

export default (props) => {
    const columnExamFinishedBody = (exam) => {
        const countOfPending = exam.UserExams.reduce((pending, userExam) => {
            if([
                CONSTS.USER_EXAM.STATUS.CANCELED,
                CONSTS.USER_EXAM.STATUS.EXPIRED,
                CONSTS.USER_EXAM.STATUS.FINISHED,
                CONSTS.USER_EXAM.STATUS.TIME_OUT
            ].includes(userExam.AppUserExamStatusId)) {
                return pending + 1;
            }
            return pending;
        }, 0);
        return `${countOfPending} / ${exam.UserExams.length}`;
    }

    const columnExamCreatedAtBody = (exam) => {
        return DateTime.fromJSDate(exam.createdAt).toLocal().toFormat('dd/MM/yyyy HH:mm');
    }

    return (
        <design.components.data.table.Table
            value={props?.exams ?? []}
            paginator
            rows={10}
            dataKey="id"
            sortField="name"
            emptyMessage="Sin datos"
            sortOrder={-1}
            filterDisplay="row" 
            selectionMode="single"
            stripedRows
            loading={props.isLoading}
            onRowSelect={props.onRowSelect}
        >
            <design.components.data.table.Column 
                style={{ width: '25%' }}
                field="name"
                header="Nombre"
                sortable
                filter
                showFilterMenu={false}
                filterPlaceholder="Nombre"
            />
            <design.components.data.table.Column 
                style={{ width: '25%' }}
                field="description"
                header="Descripción"
                sortable
                filter
                showFilterMenu={false}
                filterPlaceholder="Descripción"
            />
            <design.components.data.table.Column 
                style={{ width: '20%' }}
                field="ExamTemplate.name"
                header="Plantilla"
                sortable
                filter
                showFilterMenu={false}
                filterPlaceholder="Plantilla"
            />
            <design.components.data.table.Column 
                style={{ width: '10%', textAlign: 'center' }}
                header="Finalizados"
                body={columnExamFinishedBody}
                // sortable
                // filter
                showFilterMenu={false}
                filterPlaceholder="Finalizados"
            />
            <design.components.data.table.Column 
                style={{ width: '20%', textAlign: 'center' }}
                header="Fecha de asignación"
                field="createdAt"
                body={columnExamCreatedAtBody}
                sortable
                // filter
                showFilterMenu={false}
                filterPlaceholder="Fecha"
            />
        </design.components.data.table.Table>
    )
};
