import './DataInfo.component.scss';

const Component = {
    name: 'DataInfoComponent',
    Element: (_props) => {
        return (
            <div className={Component.name}>
                {_props.data !== undefined && 
                    <h3>{_props.data}</h3>
                }
                {
                    _props.title && 
                    <span>{_props.title}</span>
                }
            </div>
        )
    }
}

export default Component.Element;
