import useApplication from './useApplication.hook';
import useAuthentication from './useAuthentication.hook';
import useComponent from './useComponent.hook';
import useTemplate from './useTemplate.hook';

export default {
    useApplication,
    useAuthentication,
    useComponent,
    useTemplate
}
