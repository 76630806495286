import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import react from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useImmer } from 'use-immer';
import utils from 'utils';

/**
 * @template TState
 * @template TAsyncGet
 * @param {object} props
 * @param {string} props.name
 * @param {TState} props.state
 * @param {object} props.form
 * @param {object} props.form.defaultValues
 * @param {object} props.async
 * @param {Array} props.async.key
 * @param {() => Promise<TAsyncGet>} props.async.get
 * @param {() => Promise<TAsyncGet>} props.async.set
 */
export default (props) => {
    const ref = react.useRef(0);
    ref.current = ref.current + 1;

    // Defaults
    props ??= {};
    props.name ??= '';
    props.state ??= {};
    props.async ??= {};
    props.async.key ??= [];
    props.async.get ??= async () => null;
    props.async.options ??= {};
    props.async.set ??= async () => null;

    const [requests, setRequests] = react.useState([]);
    const [calls, setCalls] = react.useState([]);
    const [isLoading, setIsLoading] = react.useState(false);
    const [component] = react.useState({ name: props.name });

    const form = useForm(props.form);
    const formController = (controllerProps) => (
        <Controller 
            control={form.control}
            {...controllerProps}
        />
    );
    const formSetError = (name, error, config) => {
        if(typeof config === 'object' && typeof config.life === 'number') {
            setTimeout(() => {form.clearErrors(name)}, config.life)
        }
        return form.setError(name, error, config);
    }

    let isMount = true;
    const dismount = () => {
        isMount = false;
    }

    react.useEffect(() => {
        if (Array.isArray(requests)) {
            if (requests.length === 0) {
                setIsLoading(false);
            } else {
                setIsLoading(true);
            }
        }
    }, [requests])

    /**
     * This callback type is dispatch `stateCallback`
     *
     * @callback stateCallback
     * @param {StateComponentData} state
     */

    /**
     * @template S
     * @param  {(S | (() => S))} initialValue
     * @return {[S, ((fn: ((state: S) => void)) => void)]} 
     */
    const useCustomState = (initialValue) => {
        const [value, setValue] = useImmer(initialValue);

        const customSetValue = (newValue) => {
            if (isMount) {
                setValue(newValue);
            }
        }

        return [
            value,
            customSetValue
        ]
    }

    /**
    * @param  {Promise<any>} fn
    */
    const useAsync = (fn, options = {}) => {
        if (options?.name) {
            fnCalls({
                name: options.name,
                state: 'init'
            });
        }

        if (options?.delay === undefined) {
            options.delay = 0;
        }

        return react.useCallback(async function () {
            const id = utils.generate.uuid()

            setRequests(prevRequests => [...prevRequests, {
                id
            }]);

            const data = {}

            if (options?.name) {
                data.name = options?.name;

                fnCalls({
                    name: options.name,
                    state: 'sending'
                });
            }

            if (Object.keys(arguments).length !== 0) {
                data.props = arguments;
            }

            if (options?.logger) {
                fnLogger({
                    data,
                    state: 'sending'
                });
            }

            try {
                const result = await new Promise((resolve, reject) => {
                    setTimeout(async () => {
                        try {
                            const result = await fn.apply(this, data.props);
                            resolve(result);
                        } catch (error) {
                            reject(error);
                        }
                    }, options.delay);
                });

                if (options?.logger) {
                    fnLogger({
                        data,
                        state: 'completed',
                        response: result
                    });
                }

                if (options?.name) {
                    fnCalls({
                        name: options.name,
                        state: 'completed',
                        response: result
                    });
                }

                return result;
            } catch (error) {
                if (options?.logger) {
                    fnLogger({
                        data,
                        state: 'failed',
                        error
                    });
                }

                if (options?.name) {
                    fnCalls({
                        name: options.name,
                        state: 'failed'
                    });
                }

                throw error;
            } finally {
                setRequests(prevRequests => 
                    prevRequests.filter(request => request.id !== id)
                );
            }
        }, options.dependencies ?? []);
    }

    const queryClient = useQueryClient();
    const query = useQuery({
        queryKey: [component.name, ...props.async.key],
        queryFn: useAsync(props.async.get, props.async.options)
    });
    const mutation = useMutation({
        mutationFn: props.async.set,
        onSuccess: () => {
            queryClient.invalidateQueries({ 
                queryKey: [component.name, ...props.async.key]
            })
        }
    });

    const fnCalls = (params) => {
        if (params.state === 'init') {
            const call = calls[params.name];
            if (call === undefined) {
                setCalls(prevCalls => {
                    prevCalls[params.name] = {
                        name: params.name,
                        state: params.state,
                        calls: 0
                    }
                    return prevCalls;
                })
            }
        } else {
            setCalls(prevCalls => {
                prevCalls[params.name].state = params.state;

                if (params.state === 'sending') {
                    prevCalls[params.name].calls++;
                }

                if (params.state === 'completed') {
                    prevCalls[params.name].response = params.response;
                }

                return prevCalls;
            })
        }
    }

    const fnLogger = (params) => {
        const obj = {
            ...params.data,
            date: new Date().toLocaleString(),
            state: params.state
        };

        if (params.response) {
            obj.response = params.response;
        }

        if (params.error) {
            obj.error = params.error;
        }

        // eslint-disable-next-line no-console
        console.log(obj);
    }

    const [state, setState] = useCustomState(props.state);

    const classNames = (...args) => {
        if (args) {
            let classes = [];
            for (let i = 0; i < args.length; i++) {
                const className = args[i];
                if (!className) continue;
                const type = typeof className;
                if (type === 'string' || type === 'number') {
                    classes.push(className);
                } else if (type === 'object') {
                    // eslint-disable-next-line max-len
                    const _classes = Array.isArray(className) ? className : Object.entries(className).map(([key, value]) => (!!value ? key : null));
                    // eslint-disable-next-line max-len
                    classes = _classes.length ? classes.concat(_classes.filter((c) => !!c)) : classes;
                }
            }
            return classes.join(' ');
        }
        return undefined;
    }

    return {
        name: component.name,
        useState: useCustomState,
        useEffect: react.useEffect,
        useRef: react.useRef,
        useMemo: react.useMemo,
        useCallback: react.useCallback,
        renders: ref.current,
        useAsync,
        form: {
            Controller: formController,
            reset: form.reset,
            state: form.formState,
            handleSubmit: form.handleSubmit,
            setError: formSetError,
            clearErrors: form.clearErrors,
            setValue: form.setValue,
            getValues: form.getValues,
            register: form.register,
            unregister: form.unregister,
            watch: form.watch
        },
        async: {
            refetch: query.refetch,
            get: {
                data: query.data,
                status: query.status
            },
            set: {
                exec: mutation.mutate,
                data: mutation.data,
                status: mutation.status
            }
        },
        calls,
        isLoading,
        dismount,
        state,
        setState,
        classNames
    }
};
