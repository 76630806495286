import { Button } from 'primereact/button';
import ComponentUtils from '../../../utils/component.util';
import './Button.component.scss';

const Component = {
    name: 'ButtonComponent',

    Element: (_props) => {
        const className = ComponentUtils.classNames(Component.name, _props.className);

        return (
            <div className={className}>
                <Button
                    icon={_props.icon}
                    label={_props.label}
                    severity={_props.severity}
                    rounded={_props.rounded}
                    text={_props.text}
                    raised={_props.raised}
                    disabled={_props.disabled}
                    loading={_props.loading}
                    onClick={_props.onClick}
                    type={_props.type}
                    tooltip={_props.tooltip}
                    tooltipOptions={_props.tooltipOptions}
                />
            </div>
        )
    }
}

export default Component.Element;
