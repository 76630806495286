import CONSTS from 'consts';
import design from 'design';
import hooks from 'hooks';
import services from 'services';

export default (props) => {
    const application = hooks.useApplication();
    const component = hooks.useComponent({
        name: 'UserFormComponent',
        state: {
            element: {
                form: {
                    name: {
                        allowUpdate: false
                    },
                    lastName: {
                        allowUpdate: false
                    },
                    email: {
                        allowUpdate: false
                    },
                    roleId: {
                        allowUpdate: false
                    },
                    password: {
                        allowUpdate: false
                    },
                    AppUserStatusId: {
                        allowUpdate: false
                    },
                    submit: {
                        button: {
                            action: null,
                            label: ''
                        }
                    },
                    errors: {
                        template: {}
                    }
                }
            }
        },
        form : {
            defaultValues: {
                name: '',
                lastName: '',
                email: '',
                roleId: '',
                password: '',
                AppUserStatusId: CONSTS.USER.STATUS.ENABLED.ID
            }
        }
    });

    const initialUser = component.useMemo(() => {
        const user = {
            name: props?.user?.name ?? '',
            lastName:  props?.user?.lastName ?? '',
            email:  props?.user?.email ?? '',
            roleId:  props?.user?.UserRoles?.[0]?.AppUserRoleId ?? null,
            password:  props?.user?.password ?? '',
            confirmPassword: '',
            AppUserStatusId:  props?.user?.AppUserStatusId ?? CONSTS.USER.STATUS.ENABLED.ID
        }
        if(props?.user?.id) {
            user.id = props?.user?.id;
        }
        return user;
    }, [props.user])

    component.useEffect(() => {
        component.setState((state) => {
            const mode = props?.mode ?? 'CREATION';
            switch (mode) {
            case 'CREATION':
                state.element.form.name.allowUpdate = true;
                state.element.form.lastName.allowUpdate = true;
                state.element.form.email.allowUpdate = true;
                state.element.form.roleId.allowUpdate = true;
                state.element.form.password.allowUpdate = true;
                state.element.form.AppUserStatusId.allowUpdate = true;
                state.element.form.submit.button.action = onSubmitModeCreation;
                state.element.form.submit.button.label = 'Crear';
                component.form.reset(initialUser);
                break;
            case 'EDITION': 
                state.element.form.name.allowUpdate = true;
                state.element.form.lastName.allowUpdate = true;
                state.element.form.email.allowUpdate = true;
                state.element.form.roleId.allowUpdate = true;
                state.element.form.password.allowUpdate = true;
                state.element.form.password.required = false;
                state.element.form.AppUserStatusId.allowUpdate = true;
                state.element.form.submit.button.action = onSubmitModeEdition;
                state.element.form.submit.button.label = 'Actualizar';
                component.form.reset(initialUser);
                break;
            default:
                break;
            }
        })
    }, [props.mode, props.user])

    const onSubmitModeCreation = component.useAsync(async (user) => {
        delete user.confirmPassword;
        user.UserRoles = [{ AppUserRoleId: user.roleId }];
        delete user.roleId;
        const response = await services.user.create({ user });
        if(response.success) {
            application.showMessage('success', 'Usuario creado');
            component.setState((state) => {state.user = initialUser})
            if(typeof props.onSuccess === 'function') {
                props.onSuccess({ user });
            }
        } else {
            application.showMessage('error', 'Error al crear el usuario');
            if(typeof props.onFailed === 'function') {
                props.onFailed({ user });
            }
        }
    });

    const onSubmitModeEdition = component.useAsync(async (user) => {
        delete user.confirmPassword;
        if(!user.password) {
            delete user.password;
        }
        user.UserRoles = [{ AppUserRoleId: user.roleId }];
        delete user.roleId;
        const response = await services.user.update({ user });
        if(response.success) {
            application.showMessage('success', 'Usuario actualizado');
            component.setState((state) => {state.user = initialUser})
            if(typeof props.onSuccess === 'function') {
                props.onSuccess({ user });
            }
        } else {
            application.showMessage('error', 'Error al actualizar al usuario');
            if(typeof props.onFailed === 'function') {
                props.onFailed({ user });
            }
        }
    });

    const onSubmitDisabled = component.useMemo(() => {
        return component.isLoading;
    }, [component.isLoading])

    return (
        <div className={component.classNames(component.name)}>
            <form onSubmit={component.form.handleSubmit(component.state.element.form.submit.button.action)}>
                <design.components.layout.Row className="general">
                    <design.components.layout.Col col="4" className="pb-0">
                        <component.form.Controller
                            name="name" 
                            rules={{
                                required: 'El nombre es requerido'
                            }}
                            render={({ field, fieldState }) => (
                                <design.components.form.InputText 
                                    {...field}
                                    id={field.name} 
                                    icon="pi pi-user"
                                    label="Nombre"
                                    autoFocus
                                    error={component.form.state.errors.name?.message}
                                    disabled={component.isLoading}
                                    className={component.classNames({ 'p-invalid': fieldState.invalid })}
                                />
                            )} 
                        />
                    </design.components.layout.Col>
                    <design.components.layout.Col col="4">
                        <component.form.Controller
                            name="lastName" 
                            rules={{
                                required: 'El apellido es requerido'
                            }}
                            render={({ field, fieldState }) => (
                                <design.components.form.InputText 
                                    {...field}
                                    id={field.name} 
                                    icon="pi pi-user"
                                    label="Apellido"
                                    autoFocus
                                    error={component.form.state.errors.lastName?.message}
                                    disabled={component.isLoading}
                                    className={component.classNames({ 'p-invalid': fieldState.invalid })}
                                />
                            )} 
                        />
                    </design.components.layout.Col>
                    <design.components.layout.Col col="4">
                        <component.form.Controller
                            name="email" 
                            rules={{
                                required: 'El correo es requerido',
                                validate: (value) => {
                                    if(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) === false) {
                                        return 'Ingresa un correo valido';
                                    }
                                    return true;
                                }
                            }}
                            render={({ field, fieldState }) => (
                                <design.components.form.InputText 
                                    {...field}
                                    id={field.name} 
                                    icon="pi pi-user"
                                    label="Correo"
                                    autoFocus
                                    error={component.form.state.errors.email?.message}
                                    disabled={component.isLoading}
                                    className={component.classNames({ 'p-invalid': fieldState.invalid })}
                                />
                            )} 
                        />
                    </design.components.layout.Col>
                    <design.components.layout.Col col="4">
                        <component.form.Controller 
                            name="roleId"
                            rules={{ 
                                required: 'El rol es requerido'
                            }}
                            render={({ field, fieldState }) => (
                                <design.components.form.InputSelect
                                    {...field}
                                    id={field.name}
                                    icon="pi pi-user"
                                    label="Rol"
                                    focusInputRef={field.ref}
                                    error={component.form.state.errors.role?.message}
                                    optionValue="ID"
                                    optionLabel="NAME"
                                    options={Object.values(CONSTS.USER.ROLE)}
                                    className={component.classNames({ 'p-invalid': fieldState.invalid })}
                                />
                            )}
                        />
                    </design.components.layout.Col>
                    <design.components.layout.Col col="4">
                        <component.form.Controller 
                            name="password"
                            rules={{ 
                                required: [undefined, true].includes(component.state.element.form.password.required) ? 'La contraseña es requerida' : null,
                                minLength: {
                                    value: 8,
                                    message: 'La contraseña debe tener al menos 8 caracteres'
                                }
                            }}
                            render={({ field, fieldState }) => (
                                <design.components.form.InputPassword
                                    {...field}
                                    id={field.name}
                                    icon="pi pi-lock"
                                    label="Contraseña"
                                    autoFocus
                                    error={component.form.state.errors.password?.message}
                                    disabled={component.isLoading}
                                    className={component.classNames({ 'p-invalid': fieldState.invalid })}
                                />
                            )}
                        />
                    </design.components.layout.Col>
                    <design.components.layout.Col col="4">
                        <component.form.Controller 
                            name="confirmPassword"
                            rules={{ 
                                required: ([undefined, true].includes(component.state.element.form.password.required) || component.form.getValues('password') !== '') ? 'La contraseña es requerida' : null,
                                validate: (value) => {
                                    if (component.form.getValues('password') !== value) {
                                        return 'Las contraseñas no conciden';
                                    }
                                }
                            }}
                            render={({ field, fieldState }) => (
                                <design.components.form.InputPassword
                                    {...field}
                                    id={field.name}
                                    icon="pi pi-lock"
                                    label="Repite la contraseña"
                                    autoFocus
                                    error={component.form.state.errors.confirmPassword?.message}
                                    feedback={false}
                                    disabled={component.isLoading}
                                    className={component.classNames({ 'p-invalid': fieldState.invalid })}
                                />
                            )}
                        />
                    </design.components.layout.Col>
                    <design.components.layout.Col col="4">

                    </design.components.layout.Col>
                </design.components.layout.Row>
                <design.components.layout.Row>
                    <design.components.layout.Col col="12">
                        <design.components.form.Button 
                            type="submit" 
                            label={component.state.element.form.submit.button.label}
                            disabled={onSubmitDisabled}
                            loading={component.isLoading}
                        />
                    </design.components.layout.Col>
                </design.components.layout.Row>
            </form>
        </div>
    )
};
