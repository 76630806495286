import utils from 'utils';

export default new class extends utils.backend.Request {
    constructor() {
        super({ path: '/api/users' });
    }

    async list() {
        const response = await this.get();
        if(response.success) {
            const { users } = response.payload;
            if(users) {
                users.forEach((user) => {
                    user.createdAt = new Date(user.createdAt);
                    user.updatedAt = new Date(user.updatedAt);
                })
            }
        }
        return response;
    }
}();
