import CONSTS from 'consts';
import hooks from 'hooks';
import react from 'react';
import services from 'services';
import { io } from 'socket.io-client';
import utils from 'utils';

export const context = react.createContext();
export const Provider = (props) => {
    const component = hooks.useComponent({
        state: {
            token: undefined,
            user: undefined,
            socket: undefined
        }
    })

    component.useEffect(() => {
        const token = utils.app.cache.get('token') ?? null;
        component.setState(state => {state.token = token});
    }, [])

    component.useEffect(() => {
        let socket;

        if(typeof component.state.token === 'string') {
            if(utils.app.cache.get('token') !== component.state.token) {
                utils.app.cache.set('token', component.state.token);
            }

            socket = io(CONSTS.BACKEND.BASE_URL, {
                transports: ['websocket'],
                auth: {
                    token: component.state.token
                }
            });

            socket.on('connect', () => {
                component.setState(state => {state.socket = socket});
                Object.entries(socketListeners).forEach(([eventName, eventFn]) => {
                    socket.on(eventName, eventFn);
                });
                getUserDataAccount();
            });

            // eslint-disable-next-line no-console
            socket.on('connect_error', console.error);
            
        } else if(component.state.token === null) {
            utils.app.cache.delete('token');
            component.setState(state => {
                state.user = null;
                state.socket = null;
            });
        }

        return () => {
            if(socket) {
                Object.entries(socketListeners).forEach(([eventName, eventFn]) => {
                    socket.off(eventName, eventFn);
                })
                socket.close();
            }
        }
    }, [component.state.token])

    const socketListeners = {
        ping: (params, callback) => {
            if(typeof callback === 'function') {
                callback({
                    responseAt: new Date()
                });
            }
        }
    }

    const getUserDataAccount = async (token) => {
        token ??= component.state.token;
        const response = await services.authentication.getInfo();
        if(response.success) {
            component.setState(state => {state.user = response.payload.user});
            return response.payload;
        }
        return null;
    }

    return (
        <context.Provider value={{
            state: component.state,
            setState: component.setState,
            getUserDataAccount
        }}>
            {props.children}
        </context.Provider>
    );
}
