import ExamAssign from './Exam/ExamAssign.view';
import ExamDetails from './Exam/ExamDetails.view';
import ExamsList from './Exams/ExamsList.view';
import ExamTemplateCreate from './ExamTemplate/ExamTemplateCreate.view';
import ExamTemplateDetails from './ExamTemplate/ExamTemplateDetails.view';
import ExamTemplatesList from './ExamTemplates/ExamTemplatesList.view';
import Loading from './Loading/Loading.view';
import Login from './Login/Login.view';
import Logout from './Logout/Logout.view';
import UserCreate from './User/UserCreate.view';
import UserDetails from './User/UserDetails.view';
import UserExamDetails from './UserExam/UserExamDetails.view';
import UserExamReply from './UserExam/UserExamReply.view';
import UserExamsList from './UserExams/UserExamsList.view';
import UsersList from './Users/UsersList.view';

export default {
    ExamAssign,
    ExamDetails,
    ExamsList,
    ExamTemplateCreate,
    ExamTemplateDetails,
    ExamTemplatesList,
    Loading,
    Login,
    Logout,
    UserCreate,
    UserDetails,
    UserExamDetails,
    UserExamReply,
    UserExamsList,
    UsersList
}
