import CONSTS from 'consts';
import design from 'design';
import hooks from 'hooks';
import services from 'services';

export default (props) => {
    const application = hooks.useApplication();
    const component = hooks.useComponent({
        name: 'ExamFormComponent',
        async: {
            get: async () => {
                const [responseExamTemplates, responseUsers] = await Promise.all([
                    services.examTemplates.list(),
                    services.users.list()
                ]);

                if(responseExamTemplates.success && responseUsers.success) {
                    const examTemplates = responseExamTemplates.payload.examTemplates.filter(({ AppExamTemplateStatusId }) => {
                        return AppExamTemplateStatusId === CONSTS.EXAM_TEMPLATE.STATUS.ENABLED.ID;
                    });

                    const users = responseUsers.payload.users.filter((user) => {
                        const [{ AppUserRoleId }] = user.UserRoles;
                        return AppUserRoleId === CONSTS.USER.ROLE.EMPLOYEE.ID;
                    });

                    return { examTemplates, users };
                }
            }
        },
        state: {
            element: {
                pickList: {
                    source: []
                },
                form: {
                    name: {
                        allowUpdate: false
                    },
                    description: {
                        allowUpdate: false
                    },
                    duration: {
                        allowUpdate: false
                    },
                    dateStart: {
                        allowUpdate: false
                    },
                    dateEnd: {
                        allowUpdate: false
                    },
                    ExamTemplateId: {
                        allowUpdate: false
                    },
                    AppExamModeId: {
                        allowUpdate: false
                    },
                    AppExamStatusId: {
                        allowUpdate: false
                    },
                    UserExams: {
                        allowUpdate: false
                    },
                    submit: {
                        button: {
                            action: null,
                            label: ''
                        }
                    },
                    errors: {
                        exam: {}
                    }
                }
            }
        },
        form : {
            defaultValues: {
                name: '',
                description: '',
                duration: null,
                dateStart: null,
                dateEnd: null,
                ExamTemplateId: null,
                AppExamModeId: CONSTS.EXAM.MODE.RANDOM.ID,
                AppExamStatusId: CONSTS.EXAM.STATUS.ENABLED.ID,
                UserExams: []
            }
        }
    });

    component.useEffect(() => {
        component.setState((state) => {
            const mode = props?.mode ?? 'ASSIGN';
            switch (mode) {
            case 'ASSIGN':
                state.element.form.name.allowUpdate = true;
                state.element.form.description.allowUpdate = true;
                state.element.form.duration.allowUpdate = true;
                state.element.form.dateStart.allowCreate = true;
                state.element.form.dateEnd.allowUpdate = true;
                state.element.form.ExamTemplateId.allowDelete = true;
                state.element.form.AppExamModeId.allowUpdate = true;
                state.element.form.AppExamStatusId.allowUpdate = true;
                state.element.pickList.source = component.async.get.data?.users;
                state.element.form.UserExams.allowUpdate = true;
                state.element.form.submit.button.action = onSubmitModeCreation;
                state.element.form.submit.button.label = 'Asignar';
                break;
            case 'EDITION': 
                state.element.form.name.allowUpdate = true;
                state.element.form.submit.button.action = onSubmitModeEdition;
                state.element.form.submit.button.label = 'Actualizar';
                break;
            default:
                break;
            }
        })
    }, [props.mode, props.exam, component.async.get.data?.users])

    const onSubmitModeCreation = component.useAsync(async (exam) => {
        exam.UserExams = exam.UserExams.map(({ id }) => {return { UserId: id }});

        if(typeof exam.duration === 'string' && /^\d{2}:\d{2}$/.test(exam.duration)) {
            const [, hour, minute] = exam.duration.match(/^(\d{2}):(\d{2})$/);
            const minutes = (Number(hour) * 60) + Number(minute);
            exam.duration = minutes;
        } else {delete exam.duration}

        if(!exam.dateEnd) {
            delete exam.dateEnd;
        }

        if(!exam.description) {
            delete exam.description;
        }

        const response = await services.exam.create({ exam });
        if(response.success) {
            application.showMessage('success', 'Examen asignado');
            component.form.reset();
            component.setState((state) => {state.element.pickList.source = component.async.get.data?.users});
            if(typeof props.onSuccess === 'function') {
                props.onSuccess({ user: exam });
            }
        } else {
            application.showMessage('error', 'Error al asignar el examen');
            if(typeof props.onFailed === 'function') {
                props.onFailed({ user: exam });
            }
        }
    }, { dependencies: [component.async.get.data?.users] });

    const onSubmitModeEdition = component.useAsync(async (user) => {
        delete user.confirmPassword;
        if(!user.password) {
            delete user.password;
        }
        user.UserRoles = [{ AppUserRoleId: user.roleId }];
        delete user.roleId;
        const response = await services.user.update({ user });
        if(response.success) {
            application.showMessage('success', 'Examen actualizado');
            if(typeof props.onSuccess === 'function') {
                props.onSuccess({ user });
            }
        } else {
            application.showMessage('error', 'Error al actualizar el examen');
            if(typeof props.onFailed === 'function') {
                props.onFailed({ user });
            }
        }
    });

    const onSubmitDisabled = component.useMemo(() => {
        return component.isLoading;
    }, [component.isLoading])

    const itemTemplate = (item) => {
        return (
            <div className="flex flex-wrap p-2 align-items-center gap-3">
                <div className="flex-1 flex flex-column gap-2">
                    <span className="font-bold">{item.name} {item.lastName}</span>
                    <div className="flex align-items-center gap-2">
                        <i className="pi pi-envelope text-sm"></i>
                        <span>{item.email}</span>
                    </div>
                </div>
                <span className="font-bold text-900">{item.code}</span>
            </div>
        );
    };

    return (
        <div className={component.classNames(component.name)}>
            <form onSubmit={component.form.handleSubmit(component.state.element.form.submit.button.action)}>
                <design.components.layout.Row className="general">
                    <design.components.layout.Col col="8" className="pb-0">
                        <component.form.Controller
                            name="name" 
                            rules={{
                                required: 'El nombre es requerido'
                            }}
                            render={({ field, fieldState }) => (
                                <design.components.form.InputText 
                                    {...field}
                                    id={field.name} 
                                    icon="pi pi-user"
                                    label="Nombre"
                                    error={component.form.state.errors.name?.message}
                                    disabled={component.isLoading}
                                    className={component.classNames({ 'p-invalid': fieldState.invalid })}
                                />
                            )} 
                        />
                    </design.components.layout.Col>
                    <design.components.layout.Col col="2" className="pb-0">
                        <component.form.Controller
                            name="ExamTemplateId" 
                            rules={{
                                required: 'La plantilla es requerida'
                            }}
                            render={({ field, fieldState }) => (
                                <design.components.form.InputSelect 
                                    {...field}
                                    id={field.name} 
                                    icon="pi pi-user"
                                    label="Plantilla"
                                    optionValue="id"
                                    optionLabel="name"
                                    options={component.async.get.data?.examTemplates}
                                    autoFocus
                                    error={component.form.state.errors.ExamTemplateId?.message}
                                    disabled={component.isLoading}
                                    className={component.classNames({ 'p-invalid': fieldState.invalid })}
                                />
                            )} 
                        />
                    </design.components.layout.Col>
                    <design.components.layout.Col col="2" className="pb-0">
                        <component.form.Controller
                            name="AppExamModeId" 
                            rules={{
                                required: 'El modo es requerido'
                            }}
                            render={({ field, fieldState }) => (
                                <design.components.form.InputSelect 
                                    {...field}
                                    id={field.name} 
                                    icon="pi pi-user"
                                    label="Modo"
                                    optionValue="ID"
                                    optionLabel="NAME"
                                    options={Object.values(CONSTS.EXAM.MODE)}
                                    autoFocus
                                    error={component.form.state.errors.AppExamModeId?.message}
                                    disabled={component.isLoading}
                                    className={component.classNames({ 'p-invalid': fieldState.invalid })}
                                />
                            )} 
                        />
                    </design.components.layout.Col>
                    <design.components.layout.Col col="3" className="pb-0">
                        <component.form.Controller
                            name="duration"
                            rules={{
                                validate: (duration) => {
                                    if(/^\d{2}:\d{2}$/.test(duration)) {
                                        const [hours, minutes] = duration.split(':');
                                        if(Number(hours) === 0 && Number(minutes) < 5) {
                                            return 'La duración mínima de un examen es de 5 minutos';
                                        }
                                    }
                                    return true;
                                }
                            }}
                            render={({ field, fieldState }) => (
                                <design.components.form.InputMask 
                                    {...field}
                                    id={field.name}
                                    icon="pi pi-user"
                                    label="Duración [hh:mm]"
                                    mask="99:99"
                                    autoFocus
                                    error={component.form.state.errors.duration?.message}
                                    disabled={component.isLoading}
                                    className={component.classNames({ 'p-invalid': fieldState.invalid })}
                                />
                            )} 
                        />
                    </design.components.layout.Col>
                    <design.components.layout.Col col="3" className="pb-0">
                        <component.form.Controller
                            name="dateStart"
                            rules={{
                                required: 'La fecha de inicio es requerida'
                            }}
                            render={({ field, fieldState }) => (
                                <design.components.form.InputCalendar 
                                    {...field}
                                    id={field.name}
                                    icon="pi pi-user"
                                    label="Fecha de inicio"
                                    locale="es"
                                    minDate={new Date()}
                                    showTime
                                    hourFormat="24"
                                    showButtonBar
                                    autoFocus
                                    error={component.form.state.errors.dateStart?.message}
                                    disabled={component.isLoading}
                                    className={component.classNames({ 'p-invalid': fieldState.invalid })}
                                />
                            )}
                        />
                    </design.components.layout.Col>
                    <design.components.layout.Col col="3" className="pb-0">
                        <component.form.Controller
                            name="dateEnd"
                            rules={{
                                required: false
                            }}
                            render={({ field, fieldState }) => (
                                <design.components.form.InputCalendar 
                                    {...field}
                                    id={field.name}
                                    icon="pi pi-user"
                                    label="Fecha limite"
                                    locale="es"
                                    minDate={new Date()}
                                    showTime
                                    hourFormat="24"
                                    showButtonBar
                                    autoFocus
                                    error={component.form.state.errors.dateEnd?.message}
                                    disabled={component.isLoading}
                                    className={component.classNames({ 'p-invalid': fieldState.invalid })}
                                />
                            )} 
                        />
                    </design.components.layout.Col>
                    <design.components.layout.Col col="3" className="pb-0">
                        <component.form.Controller
                            name="AppExamStatusId" 
                            rules={{
                                required: 'El status es requerido'
                            }}
                            render={({ field, fieldState }) => (
                                <design.components.form.InputSelect 
                                    {...field}
                                    id={field.name} 
                                    icon="pi pi-user"
                                    label="Status"
                                    optionValue="ID"
                                    optionLabel="NAME"
                                    options={Object.values(CONSTS.EXAM.STATUS)}
                                    autoFocus
                                    error={component.form.state.errors.AppExamStatusId?.message}
                                    disabled={component.isLoading}
                                    className={component.classNames({ 'p-invalid': fieldState.invalid })}
                                />
                            )} 
                        />
                    </design.components.layout.Col>
                    <design.components.layout.Col col="12">
                        <component.form.Controller
                            name="description"
                            rules={{
                                required: false
                            }}
                            render={({ field, fieldState }) => (
                                <design.components.form.InputTextArea 
                                    {...field}
                                    id={field.name} 
                                    label="Descripción"
                                    rows={2}
                                    autoFocus
                                    error={component.form.state.errors.description?.message}
                                    disabled={component.isLoading}
                                    className={component.classNames({ 'p-invalid': fieldState.invalid })}
                                />
                            )}
                        />
                    </design.components.layout.Col>
                </design.components.layout.Row>
                <design.components.layout.Row>
                    <design.components.layout.Col col="12">
                        <component.form.Controller
                            name="UserExams" 
                            rules={{
                                required: 'Es necesario seleccionar al menos 1 usuario'
                            }}
                            render={({ field, fieldState }) => (
                                <design.components.data.PickList
                                    source={component.state.element.pickList?.source?.filter((user) => {
                                        const { id } = user;
                                        return field.value.some(user => user.id === id) === false;
                                    })} 
                                    target={field.value}
                                    ref={field.ref}
                                    onChange={(event) => {
                                        component.setState((state) => {
                                            state.element.pickList.source = event.source;
                                        })
                                        field.onChange(event.target);
                                    }} 
                                    itemTemplate={itemTemplate} 
                                    filter 
                                    filterBy="name"
                                    sourceHeader="Usuarios" 
                                    targetHeader="Usuarios seleccionados" 
                                    sourceStyle={{ height: '24rem' }} 
                                    targetStyle={{ height: '24rem' }}
                                    sourceFilterPlaceholder="Buscar por nombre" 
                                    targetFilterPlaceholder="Buscar por nombre"
                                    error={component.form.state.errors.UserExams?.message}
                                    disabled={component.isLoading}
                                    className={component.classNames({ 'p-invalid': fieldState.invalid })}
                                />
                            )}
                        />
                    </design.components.layout.Col>
                </design.components.layout.Row>
                <design.components.layout.Row>
                    <design.components.layout.Col col="12">
                        <design.components.form.Button 
                            type="submit" 
                            label={component.state.element.form.submit.button.label}
                            disabled={onSubmitDisabled}
                            loading={component.isLoading}
                        />
                    </design.components.layout.Col>
                </design.components.layout.Row>
            </form>
        </div>
    )
};
