import { Toast } from 'primereact/toast';
import { forwardRef } from 'react';
import ComponentUtils from '../../../utils/component.util';
import './Toast.component.scss';

const Component = {
    name: 'ToastComponent',

    Element: forwardRef((_props, ref) => {
        const className = ComponentUtils.classNames(Component.name, _props.className);

        return (
            <div className={className}>
                <Toast
                    ref={ref}
                />
            </div>
        )
    })
}

export default Component.Element;
