import { ProgressSpinner } from 'primereact/progressspinner';
import ComponentUtils from '../../../utils/component.util';
import './ProgressSpinner.component.scss';

const Component = {
    name: 'ProgressSpinnerComponent',

    Element: (_props) => {
        const otherClassName = [];
        if (_props.fully) {
            otherClassName.push('fully');
        }

        const className = ComponentUtils.classNames(Component.name, otherClassName, _props.className);

        return (
            <div className={className}>
                <ProgressSpinner />
            </div>
        )
    }
}

export default Component.Element;
