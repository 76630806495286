import components from 'components';
import CONSTS from 'consts';
import design from 'design';
import hooks from 'hooks';
import services from 'services';
import utils from 'utils';
import './ExamDetails.view.scss';

export default () => {
    const application = hooks.useApplication();
    const component = hooks.useComponent({
        name: 'ExamDetailsView',
        async: {
            key: [application.location.id],
            get: async () => {
                const { id } = application.location;
                const response = await services.exam.list({ id });
                if(response.success) {
                    return response.payload;
                }
                application.navigate(CONSTS.MENU.EXAMS.LIST.PATH);
                return null;
            }
        }
    });

    return (<>
        <div className={component.classNames(component.name)}>
            <design.components.section.Title
                goBack
                text={`Detalles del exam: ${utils.app.render(component.async.get.data?.exam?.name)}`}
            />
            <components.ExamDetails
                isLoading={component.async.get.status === 'loading'}
                exam={component.async.get.data?.exam}
            />
            <components.ExamUsersList
                isLoading={component.async.get.status === 'loading'}
                exam={component.async.get.data?.exam}
                onRowSelect={({ data: userExam }) => {
                    const { id } = userExam;
                    application.navigate(CONSTS.MENU.USER.EXAM.DETAILS.PATH, { id });
                }}
            />
        </div>
    </>)
};
