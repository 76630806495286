import components from 'components';
import CONSTS from 'consts';
import design from 'design';
import hooks from 'hooks';
import utils from 'utils';
import './UserExamDetails.component.scss';

export default (props) => {
    const component = hooks.useComponent({
        name: 'UserExamDetailsComponent',
        state: {
            examTemplate: null,
            element: {
                form: {
                    question: {
                        allowUpdate: false
                    },
                    answer: {
                        checkbox: {
                            allowUpdate: false
                        },
                        text: {
                            allowUpdate: false
                        }
                    }
                }
            }
        }
    });

    component.useEffect(() => {
        component.setState((state) => {
            state.examTemplate = props?.userExam?.Exam?.ExamTemplate ?? null;
        })
    }, [props?.userExam])

    const getCountAnswers = (userExam) => {
        switch (userExam?.AppUserExamStatusId) {
        case CONSTS.USER_EXAM.STATUS.CANCELED:
        case CONSTS.USER_EXAM.STATUS.EXPIRED:
        case CONSTS.USER_EXAM.STATUS.FINISHED:
        case CONSTS.USER_EXAM.STATUS.TIME_OUT:
            return `${userExam?.totalValidAnswers} / ${userExam?.totalQuestions}`;
        default:
            break;
        }
    }

    const getScore = (userExam) => {
        switch (userExam?.AppUserExamStatusId) {
        case CONSTS.USER_EXAM.STATUS.CANCELED:
        case CONSTS.USER_EXAM.STATUS.EXPIRED:
        case CONSTS.USER_EXAM.STATUS.FINISHED:
        case CONSTS.USER_EXAM.STATUS.TIME_OUT: {
            const calificacion = (userExam?.totalValidAnswers * 100) / userExam?.totalQuestions;
            return `${calificacion}%`;
        }
        default:
            break;
        }
    }

    return (<>
        <div className={component.classNames(component.name)}>
            <design.components.layout.Row title="Detalles del examen">
                <design.components.layout.Col col="col">
                    <design.components.data.Info 
                        data={utils.app.render(props?.userExam?.Exam?.name)}
                        title="Titulo"
                    />
                </design.components.layout.Col>
                <design.components.layout.Col col="col">
                    <design.components.data.Info 
                        data={utils.app.render(utils.date.minutesToHours(props?.userExam?.Exam?.duration), '-')}
                        title="Tiempo limite"
                    />
                </design.components.layout.Col>
                <design.components.layout.Col col="col">
                    <design.components.data.Info 
                        data={utils.app.render(utils.date.format(props?.userExam?.createdAt), '-')}
                        title="Fecha de asignación"
                    />
                </design.components.layout.Col>
                <design.components.layout.Col col="col">
                    <design.components.data.Info 
                        data={utils.app.render(utils.date.format(props?.userExam?.Exam?.dateStart), '-')}
                        title="Fecha de inicio"
                    />
                </design.components.layout.Col>
                <design.components.layout.Col col="col">
                    <design.components.data.Info 
                        data={utils.app.render(utils.date.format(props?.userExam?.Exam?.dateEnd), '-')}
                        title="Fecha limite"
                    />
                </design.components.layout.Col>
            </design.components.layout.Row>
            <design.components.layout.Row title="Resultados">
                <design.components.layout.Col col="col">
                    <design.components.data.Info 
                        data={props?.userExam?.AppUserExamStatus?.name}
                        title="Status"
                    />
                </design.components.layout.Col>
                <design.components.layout.Col col="col">
                    <design.components.data.Info 
                        data={utils.app.render(utils.date.format(props?.userExam?.dateStart), '-')}
                        title="Fecha de inicio"
                    />
                </design.components.layout.Col>
                <design.components.layout.Col col="col">
                    <design.components.data.Info 
                        data={utils.app.render(utils.date.timeBetweenDates(props?.userExam?.dateStart, props?.userExam?.dateEnd), '-')}
                        title="Respondido en..."
                    />
                </design.components.layout.Col>
                <design.components.layout.Col col="col">
                    <design.components.data.Info 
                        data={utils.app.render(getCountAnswers(props.userExam), '-')}
                        title="Preguntas correctas"
                    />
                </design.components.layout.Col>
                <design.components.layout.Col col="col">
                    <design.components.data.Info 
                        data={utils.app.render(getScore(props.userExam), '-')}
                        title="Calificación"
                    />
                </design.components.layout.Col>
            </design.components.layout.Row>
            
            {Array.isArray(component.state.examTemplate?.ExamTemplateQuestions) && 
                props.userExam.Exam.ExamTemplate.ExamTemplateQuestions.map((ExamTemplateQuestion, questionIndex) => (
                    <components.ExamTemplateQuestion
                        component={component}
                        questionIndex={questionIndex}
                        questionNumber={questionIndex+1}
                        ExamTemplateQuestion={ExamTemplateQuestion}
                    />
                ))
            }
        </div>
    </>)
};
