import './general.base.scss';

const GeneralBase = (props) => {
    return (
        <div className="GeneralBase">
            {props.children}
        </div>
    )
}

export default GeneralBase;
