import base from 'base';
import components from 'components';
import CONSTS from 'consts';
import hooks from 'hooks';
import * as reactRouterDom from 'react-router-dom';
import Views from 'views';

export default () => {
    const authentication = hooks.useAuthentication();
    const application = hooks.useApplication();
    const component = hooks.useComponent();
    const model = component.useMemo(() => {
        return [
            {
                label: CONSTS.MENU.USER.EXAMS.LIST.LABEL,
                command: () => {
                    application.navigate(CONSTS.MENU.USER.EXAMS.LIST.PATH);
                }
            },
            {
                label: <div className="menu-session">
                    <span className="logout">{CONSTS.MENU.LOGOUT.LABEL}</span>
                    <span className="username">{authentication.user.email}</span>
                </div>,
                command: () => {
                    application.navigate(CONSTS.MENU.LOGOUT.PATH);
                }
            }
        ]
    }, [application.location])

    return (
        <base.Authenticated>
            <components.Menu 
                model={model}
            />
            <div className="container">
                <reactRouterDom.Routes>
                    <reactRouterDom.Route path="/" element={<span>/</span>}/>
                    <reactRouterDom.Route path={CONSTS.MENU.USER.EXAMS.LIST.PATH} element={<Views.UserExamsList/>}/>
                    <reactRouterDom.Route path={CONSTS.MENU.USER.EXAM.DETAILS.PATH} element={<Views.UserExamDetails/>}/>
                    <reactRouterDom.Route path={CONSTS.MENU.USER.EXAM.REPLY.PATH} element={<Views.UserExamReply/>}/>
                    <reactRouterDom.Route path={CONSTS.MENU.LOGOUT.PATH} element={<Views.Logout/>}/>
                    <reactRouterDom.Route path="*" element={<reactRouterDom.Navigate to="/" replace />}/>
                </reactRouterDom.Routes>
            </div>
        </base.Authenticated>
    )
};
