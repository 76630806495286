import components from 'components';
import CONSTS from 'consts';
import design from 'design';
import hooks from 'hooks';
import services from 'services';

export default () => {
    const application = hooks.useApplication();
    const component = hooks.useComponent({
        name: 'ExamsListView',
        async: {
            get: async () => {
                const response = await services.exams.list();
                if(response.success) {
                    return response.payload;
                }
            }
        }
    });

    return (<>
        <design.components.section.Title
            text="Lista de examenes"
            buttons={[
                <design.components.form.Button 
                    type="submit" 
                    icon="pi pi-plus"
                    label={CONSTS.MENU.EXAM.ASSIGN.LABEL}
                    onClick={() => {
                        application.navigate(CONSTS.MENU.EXAM.ASSIGN.PATH);
                    }}
                />
            ]}
        />
        <components.ExamsList
            isLoading={component.async.get.status === 'loading'}
            exams={component.async.get.data?.exams}
            onRowSelect={({ data: exam }) => {
                const { id } = exam;
                application.navigate(CONSTS.MENU.EXAM.DETAILS.PATH, { id });
            }}
        />
    </>)
};
