import utils from 'utils';

export default new class extends utils.backend.Request {
    constructor() {
        super({ path: '/api/exam/templates' });
    }

    async list() {
        const response = await this.get();
        if(response.success) {
            const { examTemplates } = response.payload;
            if(examTemplates) {
                examTemplates.forEach((examTemplate) => {
                    examTemplate.createdAt = new Date(examTemplate.createdAt);
                });
            }
        }
        return response;
    }
}();
