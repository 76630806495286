export default {
    ROLE: {
        ADMIN: {
            ID: 1,
            NAME: 'Administrador'
        },
        SUPERVISOR: {
            ID: 2,
            NAME: 'Supervisor'
        },
        EMPLOYEE: {
            ID: 3,
            NAME: 'Empleado'
        }
    },

    STATUS: {
        ENABLED: {
            ID: 1,
            NAME: 'Habilitado'
        },
        SUSPENDED: {
            ID: 2,
            NAME: 'Suspendido'
        },
        BLOCKED: {
            ID: 3,
            NAME: 'Bloqueado'
        }
    }
};
