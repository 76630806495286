import CONSTS from 'consts';
import design from 'design';
import hooks from 'hooks';
import utils from 'utils';
import './ExamDetails.component.scss';

export default (props) => {
    const component = hooks.useComponent({
        name: 'ExamDetailsComponent'
    });

    const getCountUserExamsFinished = (exam) => {
        return exam?.UserExams?.reduce((counter, UserExam) => {
            const { AppUserExamStatusId } = UserExam;
            if(AppUserExamStatusId === CONSTS.USER_EXAM.STATUS.FINISHED) {
                counter++;
            }
            return counter;
        }, 0) ?? 0;
    }

    const getGeneralScore = (exam) => {
        const score = exam?.UserExams.reduce((score, UserExam) => {
            const { AppUserExamStatusId } = UserExam;
            if(AppUserExamStatusId === CONSTS.USER_EXAM.STATUS.FINISHED) {
                score.totalQuestions += UserExam.totalQuestions;
                score.totalValidAnswers += UserExam.totalValidAnswers;
            }
            return score;
        }, { totalQuestions: 0, totalValidAnswers: 0 });
        const calificacion = (score?.totalValidAnswers * 100) / score?.totalQuestions;
        if(isNaN(calificacion)) {
            return '-';
        }
        return `${calificacion}%`;
    }

    return (<>
        <div className={component.classNames(component.name)}>
            <design.components.layout.Row title="Detalles del examen">
                <design.components.layout.Col col="col">
                    <design.components.data.Info 
                        data={utils.app.render(utils.date.format(props?.exam?.dateStart), '-')}
                        title="Fecha de inicio"
                    />
                </design.components.layout.Col>
                <design.components.layout.Col col="col">
                    <design.components.data.Info 
                        data={utils.app.render(utils.date.format(props?.exam?.dateEnd), '-')}
                        title="Fecha limite"
                    />
                </design.components.layout.Col>
                <design.components.layout.Col col="col">
                    <design.components.data.Info
                        data={utils.app.render(utils.date.minutesToHours(props?.exam?.duration), '-')}
                        title="Duración"
                    />
                </design.components.layout.Col>
                <design.components.layout.Col col="col">
                    <design.components.data.Info 
                        data={utils.app.render(props?.exam?.ExamTemplate?.name, '-')}
                        title="Plantilla"
                    />
                </design.components.layout.Col>
            </design.components.layout.Row>
            <design.components.layout.Row title="Detalles de los usuarios">
                <design.components.layout.Col col="col">
                    <design.components.data.Info 
                        data={utils.app.render(props?.exam?.UserExams?.length)}
                        title="Asignados"
                    />
                </design.components.layout.Col>
                <design.components.layout.Col col="col">
                    <design.components.data.Info 
                        data={utils.app.render(getCountUserExamsFinished(props?.exam))}
                        title="Finalizados"
                    />
                </design.components.layout.Col>
                <design.components.layout.Col col="col">
                    <design.components.data.Info 
                        data={utils.app.render(getGeneralScore(props?.exam))}
                        title="Calificación general"
                    />
                </design.components.layout.Col>
            </design.components.layout.Row>
        </div>
    </>)
};
