import ComponentUtils from '../../../utils/component.util';
import './Col.component.scss';

const Component = {
    name: 'ColComponent',

    Element: (_props) => {
        const getCols = () => {
            const cols = [];
            if (_props.col) {
                switch (_props.col) {
                case 'auto':
                    cols.push('col-fixed');
                    break;
                case 'col':
                    cols.push('col');
                    break;
                default:
                    cols.push(`col-${_props.col}`);
                    break;
                }
            }
            ['sm', 'md', 'lg', 'xl'].forEach((name) => {
                if (_props[name]) {
                    cols.push(`${name}:col-${_props[name]}`);
                }
            });
            return cols;
        }

        const className = ComponentUtils.classNames(
            Component.name,
            ...getCols(),
            _props.className
        );

        return (
            <div className={className}>
                {_props.children}
            </div>
        )
    }
}

export default Component.Element;
