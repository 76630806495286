import utils from 'utils';

export default new class extends utils.backend.Request {
    constructor() {
        super({ path: '/api/user' });
    }

    async list({ id }) {
        return this.get({ id });
    }

    async create({ user }) {
        return this.post({ user });
    }

    async update({ user }) {
        return this.put({ user });
    }
}();
