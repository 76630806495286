import utils from 'utils';

export default new class extends utils.backend.Request {
    constructor() {
        super({ path: '/api/user/exam' });
    }

    async details({ id }) {
        const response = await this.get({ id });
        if(response.success) {
            const { userExam } = response.payload;
            if(userExam) {
                if(userExam?.createdAt) {
                    userExam.createdAt = new Date(userExam.createdAt);
                }
                if(userExam?.dateStart) {
                    userExam.dateStart = new Date(userExam.dateStart);
                }
                if(userExam?.dateEnd) {
                    userExam.dateEnd = new Date(userExam.dateEnd);
                }
                
                const { Exam } = userExam;
                if(Exam) {
                    if(Exam?.dateStart) {
                        userExam.Exam.dateStart = new Date(Exam.dateStart);
                    }
                    if(Exam?.dateEnd) {
                        userExam.Exam.dateEnd = new Date(Exam.dateEnd);
                    }
                }
            }
        }
        return response;
    }

    async start({ id, sessionId }) {
        return this.post('/start', { id, sessionId });
    }

    async reply({ examTemplateQuestionAnswerId, sessionId }) {
        return this.post('/reply', { examTemplateQuestionAnswerId, sessionId });
    }

    async expire({ id }) {
        return this.post('/expire', { id });
    }
}();
