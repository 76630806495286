import components from 'components';
import hooks from 'hooks';
import './Login.view.scss';

export default () => {
    const authentication = hooks.useAuthentication();
    const component = hooks.useComponent({
        name: 'LoginView'
    });

    const onLogin = ({ token }) => {
        authentication.setToken(token);
    }

    return (
        <div className={component.classNames(component.name, 'full-center')}>
            <components.Login onLogin={onLogin}/>
        </div>
    )
};
