import components from 'components';
import CONSTS from 'consts';
import design from 'design';
import hooks from 'hooks';
import services from 'services';

export default () => {
    const application = hooks.useApplication();
    const component = hooks.useComponent({
        name: 'UserDetailsView',
        async: {
            key: [application.location.id],
            get: async () => {
                const { id } = application.location;
                const response = await services.user.list({ id });
                if(response.success) {
                    return response.payload;
                }
                application.navigate(CONSTS.MENU.USERS.LIST.PATH);
                return null;
            }
        }
    });

    return (<>
        <design.components.section.Title
            goBack
            text={`Detalles del usuario: ${component.async.get.data?.user?.name}`}
        />
        <components.UserForm
            mode="EDITION"
            isLoading={component.async.get.status === 'loading'}
            user={component.async.get.data?.user}
        />
    </>)
};
