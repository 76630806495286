import CONSTS from 'consts';
import react from 'react';
import { useImmerReducer } from 'use-immer';

const reducer = (state, action) => {
    switch (action.type) {
    case CONSTS.TEMPLATE.DISPATCH.QUESTION.ADD.TYPE: {
        state.questions.push(action.payload);
        break;
    }
    default:
        throw Error(`Unknown action: ${action.type}`);
    }
}

export const context = react.createContext();
export const Provider = (props) => {
    const [state, dispatch] = useImmerReducer(reducer, {
        questions: []
    });

    return (
        <context.Provider value={{
            state,
            dispatch
        }}
        >
            {props.children}
        </context.Provider>
    );
}
