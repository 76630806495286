export default {
    STATUS: {
        ENABLED: {
            ID: 1,
            NAME: 'Habilitado'
        },
        DISABLED: {
            ID: 2,
            NAME: 'Deshabilitado'
        }
    }
};
