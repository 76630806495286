import './Table.component.scss';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

const Table = {
    name: 'TableComponent',

    Element: (_props) => {
        return <DataTable {..._props} className={Table.name} />
    }
};

export default {
    Table: Table.Element,
    Column
}
