import components from 'components';
import design from 'design';
import hooks from 'hooks';
import { Fragment } from 'react';
import './ExamTemplateQuestion.component.scss';

export default (props) => {
    const component = hooks.useComponent({
        name: 'ExamTemplateQuestionComponent'
    });
    
    const element = {
        InputTextArea: {
            ref: component.useRef(null)
        }
    }

    component.useEffect(() => {
        if(element.InputTextArea.ref?.current?.scrollHeight > 0) {
            const inputTextArea = element.InputTextArea.ref.current;
            const scrollHeight = inputTextArea.scrollHeight;
            inputTextArea.style.height = `${scrollHeight}px`;
        }
    }, [
        element.InputTextArea.ref?.current?.scrollHeight
    ])

    return (
        <design.components.layout.Row className={component.classNames(component.name)}>
            <design.components.layout.Col col="12" className="pb-0">
                <design.components.layout.Row>
                    <design.components.layout.Col className="col">
                        <design.components.form.InputTextArea
                            ref={element.InputTextArea.ref}
                            icon="pi pi-question"
                            label={`Pregunta ${props.questionNumber}`}
                            value={props.component.state.examTemplate.ExamTemplateQuestions[props.questionIndex].text}
                            onChange={(event) => {props.component.setState((state) => {state.examTemplate.ExamTemplateQuestions[props.questionIndex].text = event.target.value})}}
                            error={props.component.state?.element?.form?.errors?.examTemplate?.ExamTemplateQuestions?.[props.questionIndex]?.text}
                            disabled={props.component.state?.element?.form?.question?.allowUpdate === false || props.component.isLoading}
                            className={props.component.classNames({ 'p-invalid': props.component.state?.element?.form?.errors?.examTemplate?.ExamTemplateQuestions?.[props.questionIndex]?.text })}
                            rows={1}
                        />
                    </design.components.layout.Col>
                    {props.component.state?.element?.form?.question?.allowDelete && props.component.state.examTemplate.ExamTemplateQuestions.length >= 2 &&
                        <design.components.layout.Col className="col-fixed">
                            <design.components.form.Button 
                                type="button"
                                icon="pi pi-trash"
                                severity="danger"
                                tooltip="Eliminar pregunta"
                                tooltipOptions={{ position: 'top' }}
                                rounded
                                text
                                onClick={(event) => {
                                    const { text } = props.component.state.examTemplate.ExamTemplateQuestions[props.questionIndex];
                                    const anyAnswerIsFully = props.component.state.examTemplate.ExamTemplateQuestions[props.questionIndex].ExamTemplateQuestionAnswers.some((ExamTemplateQuestionAnswer) => {
                                        const { text } = ExamTemplateQuestionAnswer;
                                        return text.length >= 1;
                                    });
                                    const accept = () => {
                                        props.component.setState((state) => {
                                            state.examTemplate.ExamTemplateQuestions.splice(props.questionIndex, 1);
                                        })
                                    }
                                    
                                    if(text || anyAnswerIsFully) {
                                        design.methods.common.confirmPopup({
                                            target: event.currentTarget,
                                            message: 'Estas seguro que deseas continuar?',
                                            icon: 'pi pi-exclamation-triangle',
                                            acceptLabel: 'Sí',
                                            accept
                                        });
                                    } else {
                                        accept();
                                    }
                                }}
                            />
                        </design.components.layout.Col>
                    }
                </design.components.layout.Row>
            </design.components.layout.Col>
            {props.ExamTemplateQuestion.ExamTemplateQuestionAnswers.map((ExamTemplateQuestionAnswer, answerIndex) => (
                <Fragment key={answerIndex}>
                    <components.ExamTemplateQuestionAnswer
                        component={props.component}
                        questionIndex={props.questionIndex}
                        answerIndex={answerIndex}
                        answerNumber={answerIndex+1}
                    />
                </Fragment>
            ))}
            {props.component.state?.element?.form?.answer?.text?.allowCreate &&
                <design.components.layout.Col col="12" className="actions">
                    <hr/>
                    <design.components.form.Button 
                        type="button"
                        icon="pi pi-plus"
                        tooltip="Añadir respuesta"
                        tooltipOptions={{ position: 'top' }}
                        rounded
                        text
                        onClick={() => {
                            props.component.setState((state) => {
                                state.examTemplate.ExamTemplateQuestions[props.questionIndex].ExamTemplateQuestionAnswers.push({
                                    text: '',
                                    isValid: false
                                });
                            })
                        }}
                    />
                </design.components.layout.Col>
            }
        </design.components.layout.Row>
    )
};
