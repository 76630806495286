import components from 'components';
import CONSTS from 'consts';
import design from 'design';
import hooks from 'hooks';
import Joi from 'joi';
import { DateTime } from 'luxon';
import services from 'services';
import utils from 'utils';
import './UserExamReply.view.scss';

export default () => {
    const authentication = hooks.useAuthentication();
    const application = hooks.useApplication();
    const component = hooks.useComponent({
        name: 'UserExamReplyView',
        state: {
            timer: null,
            showDescription: null,
            examTemplate: {
                ExamTemplateQuestions: []
            },
            element: {
                form: {
                    question: {
                        allowDelete: false,
                        allowUpdate: false
                    },
                    answer: {
                        text: {
                            allowCreate: false,
                            allowUpdate: false,
                            allowDelete: false
                        },
                        checkbox: {
                            allowUpdate: true
                        }
                    },
                    submit: {
                        button: {
                            label: 'Siguiente'
                        }
                    },
                    errors: {
                        examTemplate: {}
                    }
                }
            },
            process: null
        },
        async: {
            key: [application.location.id],
            get: async () => {
                const response = await services.userExam.details({ id: application.location.id });
                if(response.success) {
                    const { userExam } = response.payload;
                    if(
                        userExam?.sessionId !== authentication.socket.id 
                        || userExam.AppUserExamStatusId !== CONSTS.USER_EXAM.STATUS.INITIATED
                    ) {
                        application.navigate(CONSTS.MENU.USER.EXAM.DETAILS.PATH, { id: application.location.id });
                    }
                    return response.payload;
                }
                application.navigate(CONSTS.MENU.USER.EXAMS.LIST.PATH);
                return null;
            }
        }
    });

    const validate = (schema, examTemplate) => {
        const validation = schema.validate(examTemplate, { abortEarly: false });
        component.setState((state) => {
            state.element.form.errors.examTemplate = {};
            if(validation.error) {
                validation.error.details.slice().reverse().forEach(({ message, path: paths }) => {
                    const path = paths.reduce((acc, path, index) => {
                        if(path === 'ExamTemplateQuestionAnswers' && index === paths.length -1) {
                            path = 'text';
                        }
                        if(typeof path === 'string' ) {
                            if(index !== 0) {
                                acc += '.';
                            }
                            acc += path;
                        } else if(typeof path === 'number') {
                            acc += `[${path}]`;
                        }
                        return acc;
                    }, '');
                    utils.string.pathToObject(path, message, state.element.form.errors.examTemplate);
                })
            }
        })
        return validation.error === undefined;
    }

    const onSubmitReply = component.useAsync(async ({ examTemplate }) => {
        const schema = Joi.object().keys({
            ExamTemplateQuestions: Joi.array().items(Joi.object().keys({
                ExamTemplateQuestionAnswers: Joi.array().custom((ExamTemplateQuestionAnswers, helper) => {
                    if (ExamTemplateQuestionAnswers.every(({ isValid }) => Boolean(isValid) === false)) {
                        return helper.message('Es necesario especificar minimo una respuesta correcta');
                    } else if (ExamTemplateQuestionAnswers.reduce((accumulator, { isValid }) => Boolean(isValid) ? accumulator + 1 : accumulator, 0) > 1) {
                        return helper.message('Solo puede seleccionar una respuesta valida por pregunta');
                    }
                })
            }).unknown())
        });

        if(validate(schema, examTemplate)) {
            const [ ExamTemplateQuestion ] = examTemplate.ExamTemplateQuestions;
            const { ExamTemplateQuestionAnswers } = ExamTemplateQuestion;
            const ExamTemplateQuestionAnswer = ExamTemplateQuestionAnswers.find(({ isValid }) => Boolean(isValid) === true);
            const { id: examTemplateQuestionAnswerId } = ExamTemplateQuestionAnswer;
            const response = await services.userExam.reply({ examTemplateQuestionAnswerId, sessionId: authentication.socket.id });
            if(response.success) {
                application.showMessage('success', 'Respuesta enviada');
            } else {
                application.showMessage('error', 'Error al enviar la respuesta');
            }
        }
    }, { name: 'onSubmitReply' })

    component.useEffect(() => {
        Object.entries(socketListeners).forEach(([eventName, eventFn]) => {
            authentication.socket.on(eventName, eventFn);
        });

        return () => {
            Object.entries(socketListeners).forEach(([eventName, eventFn]) => {
                authentication.socket.off(eventName, eventFn);
            });
        }
    }, [])

    component.useEffect(() => {
        let interval;
        if(component.async.get.data?.userExam) {
            const { userExam } = component.async.get.data;
            if(userExam?.dateStart && userExam?.Exam?.duration) {
                const dateLimit = DateTime.fromJSDate(userExam.dateStart).plus({ minutes: userExam.Exam.duration });
                interval = setInterval(() => {
                    const diff = dateLimit.diff(DateTime.now(), ['days', 'hours', 'minutes', 'seconds', 'milliseconds']);
                    const time = diff.toObject();
                    const { days, hours, minutes, seconds } = time;
                    if(days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
                        services.userExam.expire({ id: application.location.id });
                    }
                    component.setState((state) => {state.timer = time});
                }, 1000)
            }
            if(component.state.showDescription === null) {
                if(userExam?.totalQuestionsAnswer === 0 && userExam?.Exam?.description) {
                    component.setState((state) => {state.showDescription = true})
                } else {
                    component.setState((state) => {state.showDescription = false})
                }
            }
        }
        return () => clearInterval(interval);
    }, [component.async.get.data?.userExam])

    component.useEffect(() => {
        if(component.state.showDescription === false) {
            authentication.socket.emit('USER_EXAM_GET_QUESTION', { userExamId: Number(application.location.id) });
        }
    }, [component.state.showDescription])

    const socketListeners = {
        USER_EXAM_NOT_FOUND: ({ userExamId }) => {
            if(userExamId === application.location.id) {
                application.navigate(CONSTS.MENU.USER.EXAM.DETAILS.PATH, { id: userExamId });
            }
        },
        USER_EXAM_FINISHED: ({ userExamId }) => {
            if(userExamId === Number(application.location.id)) {
                application.navigate(CONSTS.MENU.USER.EXAM.DETAILS.PATH, { id: userExamId });
            }
        },
        USER_EXAM_TIMEOUT: ({ userId, userExamId }) => {
            if(userExamId === Number(application.location.id)) {
                application.navigate(CONSTS.MENU.USER.EXAM.DETAILS.PATH, { id: userExamId });
            }
        },
        USER_EXAM_SEND_QUESTION: ({ userExamId, examTemplateQuestion, process }) => {
            if(userExamId === Number(application.location.id)) {
                component.setState((state) => {
                    state.examTemplate.ExamTemplateQuestions[0] = examTemplateQuestion;
                    state.process = process;
                })
            }
        }
    }

    const buttons = component.useMemo(() => {
        const buttons = [];
        if(component.state.timer) {
            const { days, hours, minutes, seconds } = component.state.timer;
            buttons.push(<design.components.common.Timer 
                days={days > 0 ? days : undefined}
                hours={hours > -1 ? hours : undefined}
                minutes={minutes > -1 ? minutes : undefined}
                seconds={seconds > -1 ? seconds : undefined}
            />)
        }
        return buttons;
    }, [component.state.timer, component.state.process])

    const onSubmitDisabled = component.useMemo(() => {
        return component.calls?.['onSubmitReply']?.state === 'sending';
    }, [component.calls?.['onSubmitReply']?.state])

    return (<div className={component.classNames(component.name)}>
        <design.components.section.Title
            isLoading={component.async.get.status === 'loading'}
            text={`${component.async.get.data?.userExam.Exam.name ?? ''}`}
            buttons={buttons}
        />

        {component.state.showDescription && <>
            <design.components.common.Dialog 
                visible={true}
                closable={false}
                draggable={false}
                header="Descripción"
                style={{ width: '50vw' }}
                footer={
                    <design.components.form.Button 
                        type="button"
                        label="Continuar"
                        onClick={() => {
                            component.setState((state) => {state.showDescription = false});
                        }}
                    />
                }
            >
                {component.async.get.data?.userExam.Exam.description}
            </design.components.common.Dialog>
        </>}

        {component.state.examTemplate.ExamTemplateQuestions[0] && (() => {
            const { totalQuestions, totalQuestionsAnswered } = component.state.process;

            return <>
                <components.ExamTemplateQuestion
                    component={component}
                    questionIndex={0}
                    questionNumber={`${totalQuestionsAnswered + 1} / ${totalQuestions}`}
                    ExamTemplateQuestion={component.state.examTemplate.ExamTemplateQuestions[0]}
                />
                <design.components.layout.Col col="12">
                    <design.components.form.Button 
                        type="submit" 
                        label={component.state.element.form.submit.button.label}
                        onClick={() => { onSubmitReply({ examTemplate: component.state.examTemplate }) }}
                        disabled={onSubmitDisabled}
                        loading={onSubmitDisabled}
                    />
                </design.components.layout.Col>
            </>
        })()}
    </div>)
};
