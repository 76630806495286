
export default {
    DISPATCH: {
        QUESTION: {
            ADD: {
                TYPE: 'TEMPLATE_QUESTION_ADD_TYPE'
            }
        }
    }
};
