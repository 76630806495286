import components from 'components';
import design from 'design';
import hooks from 'hooks';

export default () => {
    const application = hooks.useApplication();

    return (<>
        <design.components.section.Title
            goBack
            text="Crear plantilla"
        />
        <components.ExamTemplateForm
            mode="CREATION"
            examTemplate={application.location?.state?.examTemplate}
        />
    </>)
};
