import { Checkbox } from 'primereact/checkbox';
import ComponentUtils from '../../../utils/component.util';
import './InputCheckbox.component.scss';

const Component = {
    name: 'InputCheckboxComponent',

    Element: (_props) => {
        const className = ComponentUtils.classNames(Component.name, _props.className);

        return (
            <Checkbox
                className={className}
                id={_props.id}
                name={_props.name}
                onChange={_props.onChange}
                checked={_props.checked}
                value={_props.value}
                disabled={_props.disabled}
            />
        )
    }
}

export default Component.Element;
