import utils from 'utils';

export default new class extends utils.backend.Request {
    constructor() {
        super({ path: '/api/user/exams' });
    }

    async list() {
        return await this.get();
    }
}();
