import { PickList } from 'primereact/picklist';
import { forwardRef } from 'react';
import ComponentUtils from '../../../utils/component.util';
import './PickList.component.scss';

const Component = {
    name: 'PickListComponent',

    Element: forwardRef((_props, ref) => {

        const createHeader = () => {
            const classNameHeader = `${Component.name}__header`;

            const error = _props.error &&
                (() => {
                    const text = _props.error.replace(_props.name, _props.label);
                    return <small className={`${classNameHeader}-error`}>{text}</small>
                })()

            return (
                <div className={classNameHeader}>
                    {error}
                </div>
            )
        }

        const createBody = () => {
            const classNameBody = `${Component.name}__body`;

            const input =
                (() => {
                    const classNameBodyInput = [];
                    if (_props.error) classNameBodyInput.push('p-invalid');

                    return (
                        <PickList
                            ref={ref}
                            source={_props.source} 
                            target={_props.target}
                            onChange={_props.onChange} 
                            itemTemplate={_props.itemTemplate} 
                            filter={_props.filter ?? false}
                            filterBy={_props.filterBy}
                            breakpoint={_props.breakpoint ?? '960px'}
                            sourceHeader={_props.sourceHeader}
                            targetHeader={_props.targetHeader}
                            sourceStyle={_props.sourceStyle} 
                            targetStyle={_props.targetStyle}
                            sourceFilterPlaceholder={_props.sourceFilterPlaceholder}
                            targetFilterPlaceholder={_props.targetFilterPlaceholder}
                            className={classNameBodyInput}
                        />
                    )
                })()

            return (
                <div className={classNameBody}>
                    {input}
                </div>
            )
        }

        const className = ComponentUtils.classNames(Component.name, _props.className);
        const header = createHeader();
        const body = createBody();

        return (
            <div className={className}>
                {header}
                {body}
            </div>
        )
    })
}

export default Component.Element;
