export default {
    LOGIN: {
        LABEL: 'Iniciar sesión',
        PATH: '/login'
    },
    LOGOUT: {
        LABEL: 'Cerrar sesión',
        PATH: '/logout'
    },
    USER: {
        CREATE: {
            LABEL: 'Nuevo usuario',
            PATH: '/user'
        },
        DETAILS: {
            PATH: '/user/:id'
        },
        EXAM: {
            DETAILS: {
                PATH: '/user/exam/:id'
            },
            REPLY: {
                PATH: '/user/exam/:id/reply'
            }
        },
        EXAMS: {
            LIST: {
                LABEL: 'Examenes',
                PATH: '/user/exams'
            }
        }
    },
    USERS: {
        LIST: {
            LABEL: 'Usuarios',
            PATH: '/users'
        }
    },
    EXAM: {
        ASSIGN: {
            LABEL: 'Asignar',
            PATH: '/exam'
        },
        DETAILS: {
            PATH: '/exam/:id'
        },
        TEMPLATE: {
            CREATE: {
                LABEL: 'Nueva plantilla',
                PATH: '/exam/template'
            },
            DETAILS: {
                PATH: '/exam/template/:id'
            },
            CLONE: {
                LABEL: 'Clonar'
            }
        },
        TEMPLATES: {
            LIST: {
                LABEL: 'Plantillas',
                PATH: '/exam/templates'
            }
        },
        USERS: {
            ASSIGNED: {
                PATH: '/exam/:id/users'
            }
        }
    },
    EXAMS: {
        LIST: {
            LABEL: 'Examenes',
            PATH: '/exams'
        }
    }
}
